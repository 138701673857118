.selected-kit-container {
  border-bottom: 1px solid #e0e1dd;
  margin-bottom: 16px;

  .kit-title {
    font-size: 24px;
    font-weight: 300;
    line-height: 1.33;
    color: #000;
  }

  .kit-info {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5;
    color: #999999;
  }
  .loan-list-date-picker {
    .app-datepicker {
      .date-picker-label {
        margin-bottom: 0 !important;
      }
    }
  }
}

.edit-info-wrapper {
  word-wrap: break-word;
}

.steps-container {
  display: flex;
  list-style: none;

  li {
    display: flex;
    align-items: center;
    margin-right: 5%;
    color: rgba(0, 0, 0, 0.3);
  }

  .active-step {
    color: #000;
  }
}

.border-steps {
  border-bottom: 1px solid #eff0ee;
  margin-bottom: 64px;
}

.single-loan-container {
  .title-loan {
    font-size: 36px;
    font-weight: bold;
    line-height: 1.11;
    color: #000;
    margin-bottom: 24px;
  }

  .subtitle-loan {
    font-size: 30px;
    font-weight: 300;
    line-height: 1.07;
    letter-spacing: normal;
    color: #000;
    margin-bottom: 16px;
  }

  .arrow-left {
    transform: scaleX(-1);
  }

  .note-text {
    font-size: 12px;
    line-height: 1.33;
    color: #000;
  }

  .title {
    font-weight: bold;
  }

  .date-picker-label {
    height: 18px;
  }

  .label-field {
    font-size: 12px;
    font-weight: 300;
    line-height: 1.33;
    color: #9a9b9c;
  }

  .cancel-button, .edit-button {
    display: flex;
    font-size: 16px;
    font-weight: 300;
    background-color: transparent;
    color: #0088d0;
    border: none;
    line-height: 1.5;
    cursor: pointer;
  }

  .editable-block {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
  }
}
.steps-container {
  display: flex;
  list-style: none;

  li {
    display: flex;
    align-items: center;
    margin-right: 5%;
    color: rgba(0, 0, 0, 0.3);
  }

  .active-step {
    color: #000;
  }
}

.border-steps {
  border-bottom: 1px solid #eff0ee;
  margin-bottom: 64px;
}

// SINGLE LOAN STYLES
.single-loan-container {
  .title-loan {
    font-size: 36px;
    font-weight: bold;
    line-height: 1.11;
    color: #000;
    margin-bottom: 24px;
  }

  .subtitle-loan {
    font-size: 30px;
    font-weight: 300;
    line-height: 1.07;
    letter-spacing: normal;
    color: #000;
    margin-bottom: 16px;
  }

  .arrow-left {
    transform: scaleX(-1);
  }

  .note-text {
    font-size: 12px;
    line-height: 1.33;
    color: #000;
  }

  .title {
    font-weight: bold;
  }

  .date-picker-label {
    height: 18px;
  }

  .label-field {
    font-size: 12px;
    font-weight: 300;
    line-height: 1.33;
    color: #9a9b9c;
  }

  .cancel-button, .edit-button {
    display: flex;
    font-size: 16px;
    font-weight: 300;
    background-color: transparent;
    color: #0088d0;
    border: none;
    line-height: 1.5;
    cursor: pointer;
  }

  .editable-block {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
  }
}

.singleLoan-Message-block{
  margin-left: 53% !important;
  margin-top: -15% !important;
  background: #F9F9F9;
  width: 326px !important;
  height: 211px;
  font-family: 'FrutigerNextRegular';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
}

.kit-loan-message-dispaly{
  background: #F9F9F9;
  // width: 326px;
  height: 201px;
  margin-bottom: -25px;
  font-family: 'FrutigerNextRegular';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  padding: 2%;
}

.kit-loan-message-display-table{
  border-collapse: separate;
  border-spacing: 0 0.4em;
}

.kit-loan-table-row-block{
  font-weight: 700;
  padding-left: 13px;
}

.kitLoan-message-block{
  margin-top: -15%;
  margin-left: 80px;
}
@media (min-width: 1366px) and (max-width:1714px) {
  .kit-loan-table-row-block{
    padding-left: 2px;
  }
  .kit-loan-message-dispaly{
    padding: 1%;
  }
  .kitLoan-message-block{
    margin-top: -17%;
    margin-left: 80px;
  }
}
@media (min-width: 1715px) and (max-width:2132px) {
  .singleLoan-Message-block {
    margin-left: 50% !important;
    margin-top: -6.5% !important;
  }
}
@media (min-width: 1920) and (max-width: 2047) {
  .kitLoan-message-block{
    margin-top: -12%;
    margin-left: 30%;
  }
}
@media (min-width: 2048) and (max-width: 2132px) {
  .kitLoan-message-block{
    margin-top: -10%;
    margin-left: 30%;
  }
}
@media (min-width: 2133px) and (max-width:3146px) {
  .singleLoan-Message-block {
      margin-left: 50% !important;
      margin-top: -5% !important;
    }
}
@media (min-width: 3147px) and (max-width:3839px){
  .singleLoan-Message-block {
    margin-left: 50% !important;
    margin-top: -3.5% !important;
  }
}
@media (min-width: 3840px) {
  .singleLoan-Message-block {
    margin-left: 40% !important;
    margin-top: -6% !important;
  }

  .kitLoan-message-block{
    margin-top: -6%;
    margin-left: 30%;
  }

  .kit-loan-message-dispaly{
    background: #F9F9F9;
    height: 201px;
    margin-bottom: -25px;
    font-family: 'FrutigerNextRegular';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    padding: 1%;
  }
}
