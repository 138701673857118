
@import "resources/styles/variables";

.delivery-document-container {

  .delivery-address-section {
    color: #000;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 16px !important;
  }
  .add-button {
   display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 30px;
    background-color: $color-blue;
    height: 40px;
    border: none;
    margin-top: 0px;
  }

  .remove-button {
    background-color: $color-blue;
    height: 40px;
    border: none;
  }

  .d-flex.align-items-center {
    position: relative;
    display: flex;
    align-items: center;;
  }
}
