.loading-container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  
  .loading {
    width: 50px;
    height: 50px;
    background: rgba(255, 255, 255, 0.6) url('../../../../resources/icons/spinner.svg') no-repeat center center;
    background-size: 100%;
  }
}
