@import "resources/styles/variables";

.input-group-field {

  .input-error {
    border-color: $error-red !important;
  }

  .input-group-append {

    .input-group-text {
      font-size: 12px;
      border-radius: 0;
      border-color: $color-light-grey;
      background-color: $color-light-grey;
    }
  }
  .input-group-prepend {
    .input-group-text {
      font-size: 0.9rem;
      font-weight: 400;
      border-radius: 0;
      border-color: $color-light-grey;
      background-color: $color-light-grey;
    }
  }
}
