@import 'resources/styles/variables';

.accept-reject-buttons {
  margin-top: 18px;

  .accept-button {
    background-color: $color-blue;
    height: 40px;
    width: 40px;
    border: none;
    margin-right: 20px;
  }
  .reject-button {
    background-color: transparent;
    border: solid 1px $color-blue;
    height: 40px;
    width: 40px;
  }
}

.request-access-permission {
  margin-left: 20px;
}
