@import 'resources/styles/variables';

.folder-icon {
  margin-right: 8px;
  margin-bottom: 2px;
}

.documents-container {
  .breadcrumbs-container {
    .breadcrumb {
      display: flex;
      align-items: center;
    }

    .breadcrumb-item {
      font-size: 12px;
      color: $color-blue;
      a {
        color: $color-blue;
      }
    }
    .breadcrumb-item.active {
      color: $color-cool-grey;
    }
    .breadcrumb-item+.breadcrumb-item::before {
      color: #9a9b9c;
      font-size: 10px;
    }
  }

  .single-folder-list-view {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    border-top: solid 1px #e0e1dd;
    padding: 8px 0;
    cursor: pointer;

    .folder-name {
      color: #0088d0;
    }
    .delete-folder-button,.edit-folder-button {
      background: transparent;
      border: none;
    }
  }

  .folder-container {
    margin-bottom: 48px;
    border-bottom: solid 1px #e0e1dd;
  }

  .single-folder-grid-view {
    position: relative;
    border: solid 1px #eff0ee;
    padding: 16px;
    overflow: hidden;
    cursor: pointer;

    .folder-image {
      height: 256px;
    }
    .folder-name {
      color: #000;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .delete-button {
      position: absolute;
      top: 16px;
      right: 10px;
      border: none;
      background: transparent;
    }
    .edit-button {
      position: absolute;
      top: 16px;
      right: 45px;
      border: none;
      background: transparent;
    }
  }

  .file-list-container {
    .list {
      max-height: 100%;
    }
  }
}
