.action-message-container {
  padding: 8px 0;
  font-size: 12px;
  font-weight: bold;

  .action-icon {
    margin-right: 8px;
  }

  .close-message {
    background: none;
    border: none;
  }

  .transaction-items {
    color: #FFFFFF;
    text-decoration: underline !important;
    padding: 5px;
  }
}
