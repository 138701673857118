@import 'resources/styles/variables';

.purchaseRequests-container {
  .card-container {
    margin-bottom: 30px;
    .card-block {
      min-height: 194px;
      height: 100%;
      background-color: $color-blue;
      color: $white;
      padding: 16px;
      line-height: 1.5;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
  
      .title {
        font-size: 18px;
        font-weight: bold;
      }
      .text-bold {
        font-weight: bold;
      }
    }
  
    .add-card-block {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      min-height: 194px;
      border: dashed 1px $color-blue;
      background-color: $white;
      color: $color-blue;
      cursor: pointer;
    }
  }

  .text-gray {
    color: $color-cool-grey;
  }

  .edit-button,.delete-button {
    background: transparent;
    border: none;
  }

  .purchase-requests-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 4px;

    tr {
      vertical-align: top;
    }

    td {
      &:first-child {
        min-width: 170px;
      }

      &:nth-child(2) {
        width: 100%;
      }

      &:last-child {
        min-width: 66px;
      }
    }
  }
}
