@import 'resources/styles/variables';

.kit-details {
  .information-container {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5;
  }

  .title {
    font-size: 36px;
    font-weight: bold;
    line-height: 1.11;
  }

  .section-title {
    font-size: 30px;
    font-weight: 300;
    line-height: 1.07;
    margin-bottom: 24px;
    margin-top: 32px;
  }

  .subtitle {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    margin-bottom: 16px;
  }

  .comment-title {
    font-size: 24px;
    font-weight: 300;
    line-height: 1.33;
    margin-bottom: 16px;
  }

  .kit-number-text {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5;
    color: #747678;
    margin-bottom: 16px;
  }

  .full-description {
    display: flex;
    align-items: center;
    color: #0088d0;
    margin-bottom: 16px;
    cursor: pointer;
  }

  .list-price {
    font-size: 12px;
    font-weight: 300;
    line-height: 2;
    color: #9a9b9c;
  }

  .price {
    font-size: 30px;
    font-weight: 300;
    line-height: 1.07;
    margin-bottom: 16px;
    color: #000;
  }

  .icon-button {
    display: flex;
    align-items: center;
    width: 40px;
    height: 40px;
    padding: 8px;
    background-color: transparent;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 8px;
    }

    .tooltiptext {
      position: absolute;
      display: none;
      z-index: 1000;
      width: auto;
      min-width: 60px;
      top: 48px;
      left: 50%;
      transform: translate(-50%, 0);
      padding: 2px 5px;
      color: #000;
      background-color: #fff;
      font-size: 12px;
      border-radius: 5px;
      box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
      text-align: center;
      white-space: nowrap;

      i {
        position: absolute;
        bottom: 100%;
        left: 50%;
        margin-left: -10px;
        width: 20px;
        height: 10px;
        overflow: hidden;

        &::after {
          content: '';
          position: absolute;
          width: 10px;
          height: 10px;
          left: 50%;
          transform: translate(-50%, 50%) rotate(45deg);
          background-color: #fff;
          box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
        }
      }
    }

    &:hover {
      opacity: 0.8;
    }

    &:hover .tooltiptext {
      display: block;
    }
  }

  .button-blue {
    @extend .icon-button;
    position: relative;
    color: $color-blue;
    border: solid 1px $color-blue;
  }

  .button-red {
    @extend .icon-button;
    position: relative;
    color: $color-red;
    border: solid 1px $color-red;
  }

  .dummy-image {
    width: 250px;
    height: 250px;
    border: 1px solid #9a9b9c;
  }

  .images-container {
    height: 250px;
    overflow-y: auto;

    .image {
      opacity: 0.4;
      margin-bottom: 16px;
    }

    .active-image {
      opacity: 1;
    }
  }

  .word-wrap {
    word-wrap: break-word;
  }

  .dummy-data {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    font-size: 18px;
    font-weight: bolder;
  }

  .description-container {
    white-space: pre-line;
  }

  .message-load-more-button {
    min-width: 300px;
  }

  .grCode-image {
    width: 200px;
  }

  .text-gray {
    color: $color-cool-grey;
  }

  .showMore-button {
    padding: 0;
    font-weight: normal !important;
    border: none !important;
  }

  .answer-button {
    width: auto;
    font-size: 14px;
    border: none;
    padding: 0;
  }

  .answerQuestion-block {
    margin-bottom: 32px;
  }

  .createdBy-text {
    font-size: 18px;
  }

  .createdBy-block {
    margin-left: 35px;
  }
}

.reservation-text {
  font-size: 18px;
  color: $color-cool-grey;
}

.kit-print-container {
  margin-top: 2cm;
  max-width: 22cm;
  .print-qrcode {
    width: 320px;
    margin: -50px;
  }
  .bold {
    font-family: $font-weight-bold !important;
  }
}
.print-hidden {
  height: 0;
  opacity: 0;
  transform: translateX(-100%);
}

.percentage-item {
  min-width: 180px;
  padding: 1rem;
  padding-top: 1.4em;

  p {
    padding-top: 0.3rem !important;
  }
}
.statistics-datepicker {
  width: 240px;
  margin-left: 10px;
}

.editional-comment-wrapper {
  position: relative;
  margin-left: 20px;
  padding-left: 60px;
  margin-bottom: 20px;
}

.editional-comment-wrapper:before {
  content: '';
  position: absolute;
  border-left: 1px solid $color-blue;
  top: 0;
  bottom: -20px;
  left: -1px;
}

.editional-comment-wrapper:last-child:before {
  bottom: auto;
  height: 51%;
}

.editional-comment-wrapper:after {
  content: '';
  border-bottom: 1px solid $color-blue;
  top: 50%;
  left: -1px;
  width: 40px;
  position: absolute;
}

.arrow-right {
  position: absolute;
  width: 0; 
  height: 11px; 
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid $color-blue;
  top: 50%;
  margin: -5px 0 -5px -21px;
}

.new-style-heading{
  font-size: 20px;
  font-weight: 600;
  line-height: 24.12px;
  color: rgba(0, 0, 0, 1);
}

.components-description{
  font-size: 14px;
  line-height: 15px;
  margin-top: 10px;
}

.component-edit-button{
  border: 1px solid rgba(0, 136, 208, 1);
  background: white;
  color: rgba(0, 136, 208, 1);
  font-size: 16px;
  padding: 8px;
  width: 70.98px;
  height: 37px;
}

.dust-comment-name-date{
  font-size: 14px;
  line-height: 15px;
  font-weight: 300;
  color: rgba(0, 0, 0, 1);
}

.dust-bold-text{
  font-size: 14px;
  line-height: 15px;
  font-weight: 600;
  color: rgba(0, 0, 0, 1);
}
