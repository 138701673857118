
.c2c-tab-headers{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 15px;
    margin-top: 5px;
}

.left-buttons {
    display: flex;
    gap: 15px;
}
  
.right-button {
    margin-left: auto;
}

.c2c-tab-btn{
    color: #000000;
    border: 1px solid #E0E1DD;
    font-weight: 300px;
    font-size: 12px;
    line-height: 11.04px;
    width: fit-content;
    padding: 10px;
    &.active{
        color:#0088D0;
        border-top-width: 1px;
        border-color: #0088D0;
    }
}
.btn-whiter{
    color: #FFFFFF;
    font-weight: 500px;
    font-size: 16px;
    line-height: 24px;
    width: fit-content;
}
