.text-break {
  white-space: pre-line;
}
  
.internal-notes-container {
  textarea.form-control {
    height: 365px;
  }
}

.delivery-address-container {
  textarea.form-control {
    height: 150px;
  }
}
