@import "resources/styles/variables";

.workflow-container {
  .title {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.2;
    color: #000;
    padding-top: 8px !important;
  }

  .buttons-container {
    margin-top: 24px !important;
  }

  .input-container {
    margin: 16px 0;
  }

  .text-input-container {
    display: flex;
    padding: 0 16px 0 0;

    .text-field {
      padding: 0;
    }
    
    .text-input-label-view {
      padding: 8px;
      margin-top: auto;
      height: 40px;
      background-color: #e0e1dd;
      font-size: 12px;
      font-style: normal;
      font-stretch: normal;
      line-height: 2;
      letter-spacing: normal;
      color: #000;
    }
  }

  .delete-email-button {
    background-color: #ffffff;
    height: 36px;
    border: solid 1px $color-blue;
    margin-top: 19px;
  }
  .add-email-button {
    background-color: $color-blue;
    height: 36px;
    border: none;
    margin-top: 19px;
  }
  .refubishment-email-label{
    font-size: 12px;
  }
}

.pickup-request-email{
  display: flex;
}

.days{
  // padding: 5px 10px;
  width: 35px;
  height: 35px;
  margin: 10px;
  border: 1px solid #E0E1DD;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.selected-days{
    // padding: 5px 10px;
    width: 35px;
    height: 35px;
    margin: 10px;
    border: 1px solid #E0E1DD;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0088D0;
    color: #FFFFFF;
    cursor: pointer;
}

.divider-line-slot-list{
  border-bottom: 1px solid #E0E1DD;
  width: 34%;
}

.solt-availabilty-title{
  font-size: 18px;
  font-weight: 600;
}

.input-icon {
  height: 16px;
  width: 16px;
  font-size: 11px;
  font-weight: 600;
  text-align: center;
  margin-left: 10px !important;
  border-radius: 50%;
  border: solid 1px $color-cool-grey;
  color: $color-cool-grey;
}
.info-desc {
  width: 1050px;
  background-color: #000000;
  color: #ffffff;
  display: none;
  top: 30px;
  z-index: 999;
  padding: 5px;
  border-radius: 2px;
}
p.input-icon:hover + .info-desc {
  display: block;
  margin-top: -25px;
}
.input-icon {
  cursor: pointer;
  position: relative;
}
