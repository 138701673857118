@import 'resources/styles/variables';

.add-kit-loan-button {
  margin-bottom: 6px;
}
.error-message {
  margin-top: 5px;
  color: $color-red;
  font-size: 12px;
  &.hide {
    opacity: 0;
  }
}

.extra-message {
  font-size: 16px;
  margin-bottom: 15px;
}
