@import 'resources/styles/variables';

.activate-button {
  font-size: 12px;
}

.deactivate-button {
  font-size: 12px;
  color: $color-red;
}
