.mobile-menu  {
  padding: 16px 0 16px 0;

  .menu-options {
    .nav-item {
      margin-left: 32px;
    }
    @media (max-width: 425px) {
      .nav-item {
        margin-left: 6px;
      }
    }
  }

  .user-menu {
    .nav-item {
      padding: 16px 12px;
      position: relative;

      img {
        height: 32px;
        width: 32px;
      }
    }

    .dropdown-menu {
      left: auto !important;
      right: 12px;
      top: 4px !important;
      border: none;
      -webkit-box-shadow: 0 13px 13px -3px rgba(64,64,64,0.53);
      -moz-box-shadow: 0 13px 13px -3px rgba(64,64,64,0.53);
      box-shadow: 0 13px 13px -3px rgba(64,64,64,0.53);
    }

    .nav-item.show {
      -webkit-box-shadow: 0 13px 13px -3px rgba(64,64,64,0.53);
      -moz-box-shadow: 0 13px 13px -3px rgba(64,64,64,0.53);
      box-shadow: 0 13px 13px -3px rgba(64,64,64,0.53);
    }

    .dropdown-item {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}

@media (max-width: 425px) {
  .mobile-menu .user-menu .dropdown-menu {
    right: -11px;
  }
}

@media (max-width: 375px) {
  .mobile-menu .user-menu .dropdown-menu {
    right: -84px;
  }
}
