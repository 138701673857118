@import "resources/styles/variables";

.add-more-users {

  .add-button {
    background-color: $color-blue;
    height: 40px;
    border: none;
  }
  @media (max-width: 425px) {
    .add-button {
      margin-top: -6px;
      margin-bottom: 18px;
    }
  }

  .remove-button {
    background-color: #ffffff;
    height: 40px;
    border: solid 1px $color-blue;
    margin-top: 18px;
  }
  @media (max-width: 425px) {
    .remove-button {
      margin-top: -6px;
      margin-bottom: 18px;
    }
  }

  .button-with-text {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 40px;
    max-width: 200px;
    font-weight: bold;
    background-color: transparent;
    margin-top: 18px;
  }
  .add-button-with-text {
    @extend .button-with-text;
    background-color: transparent;
    border: 1px solid $color-blue;
    color: $color-blue;
  }
  .remove-button-with-text {
    @extend .button-with-text;
    border: solid 1px $color-red;
    color: $color-red;
  }

  .error-message {
    color: #cc0000;
    font-size: 14px;
  }
}
