@import "resources/styles/variables";

.search-icon {
  position: absolute;
  top: 8px;
  right: 12px;
}

.input-search {
  width: 100%;
  height: 40px;
  color: $color-dark-gray;
  padding: 8px 36px 8px 12px;
  border: 1px solid $color-cool-grey;
}
