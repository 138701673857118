@import "resources/styles/variables";

.input-field {

  input {
    border-color: $color-light-grey;
    height: 40px;
  }

  .input-error {
    border-color: $error-red !important;
  }

  .search-icon {
    position: absolute;
    right: 20px;
    top: 8px;
  }

  .text-danger {
    font-size: 12px;
    color: $error-red !important;
  }

  .input-info:hover + .info-message {
    display: block;
  }
}

.new-version-search-icon{
  position: absolute;
  z-index: 999;
  top: 30px;
  left: 22px;
}

.input-cursor-adjust{
  padding-left: 35px !important;
}

.new-search-icon-adjust {
  position: absolute;
  right: 28px;
  top: 28px;
}

.new-refresh-icon-styles{
  width: 48px;
  height: 32px;
  border: 2px solid $color-blue;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.custom-tooltip {
  position: absolute;
  background-color: #f5f5f5; /* Light gray background similar to default tooltip */
  color: #000; /* Black text color */
  padding: 5px;
  border-radius: 3px;
  font-size: 12px;
  z-index: 1000;
}

/* inputFieldStyles.scss */
.input-field .d-flex {
  cursor: pointer;
}
