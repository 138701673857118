@import "resources/styles/variables";

.pagination-container {
  font-size: 12px;
  
  .pagination {

    .active .page-link {
      color: $color-blue;
      background-color: transparent;
      border-bottom: 1px solid $color-blue;
    }
    
    .page-link {
      color: $color-cool-grey;
      background-color: transparent;
      border: none;
      cursor: pointer;
    }

    .break {
      display: flex;
      align-items: center;
    }

    .disabled-link {
      background-color: transparent;

      a {
        color: $color-off-white;
      }
    }
    .next{
      font-size: 12px;
    }
  }
}
