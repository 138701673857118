
.request-title{
    font-size: 30px;
    font-weight: 400;
    line-height: 36.31px;
}

.adjust-button-width{
    width: fit-content;
    padding-inline: 1.5rem;
}

.booking-tab-booking-data{
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;   
}

.border-for-booking-data{
    border: 1px solid #E0E1DD;
    padding: 10px;
}

.line-between-booking{
   border: 1px solid #efefef
}

.iqs-request-font-color{
    color: #000000;
}