.createAddressModal {
  .content {
    padding: 24px;

    .modal-close {
      top: 8px;
      right: 8px;
    }

    .form-title {
      font-size: 30px;
      font-weight: 300;
    }
  }
}
