@import "resources/styles/variables";
@import "resources/styles/mixins";

.app-dropdown {
  background-color: #ffffff;
  position: relative;
  border: solid 1px $color-light-grey;
  height: 40px;
  padding: 8px 12px;
  font-size: 16px;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  .dropdown-placeholder {
    padding-left: 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: calc(100% - 20px);
  }

  .dropdown-items-container {
    width: calc(100% + 2px);
    position: absolute;
    top: 0;
    left: -1px;
    z-index: 999;
    background-color: #ffffff;
    padding: 8px;
    border: solid 1px $color-light-grey;
    border-top: none;


    .dropdown-title {
      padding-left: 4px;
    }
  }

  .dropdown-option {

    &:focus {
      outline: none;
      box-shadow: none;
    }
    &.opacity-50 {
      opacity: 0.4;
    }
  }

  .search-icon {
    position: absolute;
    top: 47px;
    right: 15px;
  }

  .options-container {
    max-height: 343px;
    overflow-y: auto;
    margin-top: 16px;
  }

}

.input-error {
  border-color: $error-red !important;
}

.disabled {
  background: $color-light-grey;
}

.dropdown-label {
  font-size: 14px;
  color: #000;
}

.dropdown-shadow {
  @include createBoxShadow(2px, 5px, 16px, -12px, rgba(0, 0, 0, 0.75));
}

.input-info:hover + .info-message {
  display: block;
}

.new-updated-dropdown{
  border: 1px solid #E0E1DD !important;
}

.new-dropdown-placeholder{
  color: #9A9B9C !important;
}
.slected-value-placeholder{
  color: #000000 !important;
}

