.kits-file-list-container {
  // display: flex;
  // flex-direction: row;
  padding-right: 5px;
  .file-uploader-container {
    height: 100%;
  }
  .uploader-container {
    height: 100%;
  }
  .file-uploader-container > div {
    height: 100%;
  }

  .kits-picture-upload {
    width: 225px;
    height: 225px;
    .allowed-formats-text{
      display: none;
    }
  }

  .kits-pic-list {
    .list {
      display: flex;
      flex-direction: column;
      overflow: auto;
      width: 225px;
      height: 225px;
      padding: 2px;
    }
  }
}

.minimised-picture-info {
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  padding: 0.5rem;
  margin-bottom: 5px;
  width: 100%;
  .file-description {
    width: 100%;
    .file-name {
      font-size: 12px;
      color: #020814;
      line-height: 22px;
      font-weight: 400;
      letter-spacing: 0.3%;
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .delete-file-button {
    background: transparent;
    border: none;
    flex-shrink: 0;
  }
}

.create-kit-button {
  width: 110px;
  padding: 7px 10px 7px 10px;
  font-weight: 700;
}

.kit-decription-textarea {
  height: 88px;
}
