.search-container {
  border: 1px solid #e8e8e8;
  padding: 20px;
}

.search-container-left {
  border: 1px solid #e8e8e8;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.search-container-right {
  border: 1px solid #e8e8e8;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-left: 0;
}

.term-input-container {
  flex-grow: 1;
}

.add-btn-plus {
  min-width: 200px;
  max-width: 210px;
}

.save-btn-search-term{
  width: 100px;
}

.add-edit-term-container {
  border: 1px solid #e8e8e8;
  border-radius: 0;
  // padding: 12px 16px 12px 16px;
  margin-top: 16px;

  .header {
    background-color: #f7f7f7;
    font-size: 16px;
    padding: 12px 16px 12px 16px;
    border-bottom: 1px solid #e8e8e8;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .edit-icon {
      font-weight: 600;
      font-size: 14px;
      color: #0088d0;
      border-right: 2px solid #e8e8e8;
      padding-right: 10px;
    }
    span {
      cursor: pointer !important;
    }
  }

  .input-container {
    padding: 12px 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1.5rem;
    button {
      margin-left: 10px;
      border: none;
      background-color: #0088d0;
      color: #ffffff;
      font-size: 14px;
      padding: 8px 8px;
      // border-radius: 4px;
      cursor: pointer;
      // margin-top: 1rem;
      // align-self: flex-end;
    }
    .cancel-button {
      background-color: #fff !important;
      border: 1px solid #E0E1DD;
    }
  }

  .terms-container {
    padding: 12px 16px;
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    .term {
      padding-inline: 12px;
      border-radius: 2px;
      background-color: #f2f4f7;
      padding-block: 5px;
    }
  }
}

.save-btn-search-header{
  margin: 12px 16px;
}

.my-inline-btn{
  display: inline-block;
  width: 210px;
}

.add-header{
  display: inline-block;
}