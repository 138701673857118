@import "resources/styles/variables";

.breadcrumbs-container {
  margin-top: 10px;
  cursor: pointer;

  .breadcrumb-item {
    font-size: 12px;
    color: $color-blue;
    font-weight: 500;

    a {
      color: $color-blue;
    }
  }

  .breadcrumb-item.active {
    color: $color-cool-grey;
    cursor: default;
  }

  .breadcrumb-item.active {
    color: $color-cool-grey;
  }

  .breadcrumb-item+.breadcrumb-item::before {
    content: '\f054';
    font-family: 'FontAwesome';
    color: #9a9b9c;
    font-size: 10px;
    margin-top: 2px;
  }
}
