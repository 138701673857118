@import "resources/styles/variables";

.app-radio-button {
  button {
    background-color: transparent;
    border: solid 1px $color-blue;
    color: $color-blue;
    height: 40px;
    // max-width: 68px;
    &:hover {
      color: $color-blue;
    }
  }


}

.input-error {
  border-color: $error-red !important;
}

.radio-button-flex{
    display: flex;
    gap: 10px;
    align-items: center;
}

.circle-before{
    border: 1px solid #0088D0;
    border-radius: 10px;
    width: 16px;
    height: 16px;
    .active {
      background-color: $color-blue;
      color: #ffffff;
    }
}

.tick-mark-check {
  color: white;
  width: 25px;
  height: 25px;
  margin-top: 2px !important;
  margin-left: 2px;
}