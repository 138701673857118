@import "resources/styles/variables";

.small-button {
  height: 24px;
  font-size: 12px;
  background-color: transparent;
  color: $color-blue;
  border: solid 1px $color-blue;
  font-weight: bold;
}

.round {
  width: 10px;
  height: 10px;
  border-radius: 5px;
}

.yellow-round {
  @extend .round;
  background-color: #f8e71c;
}

.green-round {
  @extend .round;
  background-color: #4ab300;
}

.blue-round {
  @extend .round;
  background-color: $color-blue;
}

.red-round {
  @extend .round;
  background-color: #d0021b;
}

.yellow-round {
  @extend .round;
  background-color: #f8e71c;
}

.orange-round {
  @extend .round;
  background-color: #f8b909;
}

.round-max {
  width: 11px;
  height: 11px;
  border-radius: 12px;
}

.green-round-max {
  @extend .round-max;
  background-color: #4ab300;
}

.blue-round-max {
  @extend .round-max;
  background-color: $color-blue;
}

.red-round-max {
  @extend .round-max;
  background-color: #d0021b;
}

.yellow-round-max {
  @extend .round-max;
  background-color: #f8e71c;
}

.orange-round-max {
  @extend .orange-round;
  background-color: #f8b909;
}

.gray-round {
  @extend .round;
  background-color: #7f7f7f;
}

.gray-round-max {
  @extend .gray-round;
  background-color: #7f7f7f;
}

.orange-round-max-zmcc {
  @extend .orange-round;
  background-color: orange;  
}

.orange-round-zmcc {
  @extend .round;
  background-color: orange;  
}

.red-light-round {
  @extend .round;
  background-color: #ff0000;
}

.red-light-round-max {
  @extend .round;
  background-color: #ff0000;
}

.red-pro-round {
  @extend .round;
  background-color: #c00001;
}

.red-pro-round-max {
  @extend .red-pro-round;
  background-color: #c00001;
}

.kit-title {
  font-size: 36px;
  line-height: 1.11;
  font-weight: bold;
  color: #000;
}

.kit-table {
  tr th:not(:last-child, :first-child) {
    width: 160px;
  }

  tr th:first-child {
    width: 50px;
  }

  tr td:nth-child(2) span {
    white-space: nowrap;
  }

  tr th:nth-child(3) > div {
    justify-content: center;
  }
}

.f-16{
  font-size: 15px !important;
  font-weight: 600 !important;
}

.kit-statuses-conditions-container{
  cursor: pointer;
  position: absolute;
}

.kit-status-condition-tooltip{
  display: none;
  position: absolute;
  padding: 10px 40px 10px 10px;
  gap: 10px;
  background-color: #EFF0EE;
  transition: opacity 0.3s ease-in-out;
  z-index: 900;
  top: 0px;
}

.kit-statuses-conditions-container:hover{
  span {
    font-weight: 600;
    margin-left: 9px !important;
  }
  .kit-status-condition-tooltip{
    display: block;
  }
}

.dust-tooltip-container{
  position: relative;

  &:hover{
    .dust-tooltip-container-tooltip{
      display: flex;
      width: 100%;
      align-items: center;
      flex-shrink: 0;
      overflow: visible;
    }
  }
}

.dust-tooltip-container-tooltip{
  width: 100%;
  position: absolute;
  display: none;
  padding: 10px;
  background-color: #EFF0EE;
  transition: opacity 0.3s ease-in-out;
  z-index: 900;
  top: 0px;
  left: 40%;
  min-width: 175px;
}

.adjust-padding-button{
  padding: 7px 10px 7px 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16.88px;
}

.kit-heading-title{
  font-size: 30px;
  font-weight: 600;
  line-height: 36.18px;
}

.add-padding-create-kit-button{
  padding: 8px 16px 8px 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16.88px;
}

.add-padding-for-demo-request-button{
  padding: 7px 15px 7px 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16.88px;
}

.icon-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  display: none;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    background: black;
    padding: 8px;
    border: 1px solid $color-light-grey;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    color: #ffffff;
    transition: opacity 0.2s ease-in-out;
    opacity: 0;
    width: max-content;
}


.icon-container:hover .tooltip {
  display: block;
  opacity: 1;
}
