@import 'resources/styles/variables';

.opportunity-container {
  .opportunity-list-item  {
    border-bottom: solid 1px $color-off-white;
    padding: 6px 0;

    &:first-child {
      border-top: solid 1px $color-off-white;
    }
  }
  .delete-button {
    width: 38px;
    background: none;
    border: none;
    color: $color-blue;
    display: flex;
    align-items: center;
  }
  .add-button {
    height: 40px;
    background: none;
    border: solid 1px $color-blue;
    color: $color-blue;
    display: flex;
    align-items: center;
  }
  .disabled-button {
    border: solid 1px $color-midle-blue;
  }

  .text-bold {
    font-weight: bold;
  }
}
