@import "resources/styles/variables";

.userPermissions-container {
  .custom-table {
    overflow: visible;
  }

  .deletePermissions-button {
    width: auto;
    color: $color-red;
    border: 1px solid $color-red;
  }

  .active-item {
    color: $color-blue;
  }
}

.delete-all-permision-adjust-width{
  width: 41vw !important;
}
