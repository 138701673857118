@import 'resources/styles/variables';

.sellOff-title {
  color: #000;
  font-size: 30px;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 32px !important;
}

.shop-managers-container {
  .add-button {
    margin-top: 18px;
  }
  
  .shop-managers-list {
    border-top: solid 1px $color-off-white;
    margin-top: 15px;
  
    .shop-managers-list-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: solid 1px $color-off-white;
      padding: 6px 0;
  
      .delete-button {
        background: none;
        border: none;
        color: $color-blue;
        display: flex;
        align-items: center;
      }
    }
  }
}
