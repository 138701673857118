@import "resources/styles/variables";

.main-container {
  padding: 32px;
  margin-top: 64px;

  .nav-link {
    color: #000 !important;
    padding: 0;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5;
  }

  .nav-link:hover{
    color: $color-blue !important;
  }

  .active {
    color: $color-blue !important;
    font-weight: bold;
    font-size: 16px;
    line-height: 1.5;
  }

  .footer-header-text {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    color: #000;
  }

  .footer-options-text {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5;
    color: #000;
  }

  .bottom-text{
    font-size: 12px;
    line-height: 1.33;
    color: #87898b;
    margin-right: 16px !important;
  }
}
