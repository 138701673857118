@import "resources/styles/variables";

.input-error {
  border-color: $error-red !important;
}

.radio-button-flex {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-left: -7px !important;
}

.circle-before {
  display: flex;  // Added to center the content
  justify-content: center;  // Horizontally center the tick mark
  align-items: center;  // Vertically center the tick mark
  border: 1px solid grey;
  border-radius: 50%;  // Ensure it's a perfect circle
  width: 20px;
  height: 20px;

  &.active {
    background-color: white;
    background: #0088D01A;
    border: 1px solid $color-blue;

  }
}

.tick-mark {
  color: $color-blue;
  background-color: $color-blue;
  width: 10px;
  height: 10px;
  border-radius: 50%;  // Ensure it's a circle
  margin: auto;
}
