.shop-container {
  .title {
    font-size: 24px;
  }
  .button-text {
    font-size: 16px;
    font-weight: bold;
    color: #0088d0;
    cursor: pointer;
  }
}

.shop-table {
  table {
    thead th {
      vertical-align: baseline;
    }
    th > div {
      height: auto;
    }

    tr td:first-child {
      white-space: nowrap;
    }
  }
}

.views {
  border: none !important;
}
