.adjust-dates-form-row {
  flex: 1;
  margin-left: 0 !important;
  margin-right: 0 !important;
  justify-content: space-around;

  .form-group {
    width: 49%;
  }
}
