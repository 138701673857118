@import "resources/styles/mixins";

.loginModal {

  .content {
    padding: 24px;

    .login-title {
      font-size: 30px;
      font-weight: 300;
      margin-bottom: 16px !important;
    }

    .description {
      font-size: 16px;
      font-weight: 300;
      margin-bottom: 24px !important;
    }

    .benefit-title {
      font-size: 20px;
      font-weight: bold;
      margin-top: 24px !important;
      margin-bottom: 16px !important;
    }

    .benefits {
      margin-bottom: 24px !important;

      li {
        font-size: 16px;
        font-weight: 300;
        margin-bottom: 8px !important;
      }
    }

    .modal-close {
      top: 8px;
      right: 8px;
    }
  }

  .modal-dialog {
    @include desktop{
      margin-top: 160px;
    }
  }
}
