@import "resources/styles/variables";

.input-field {

  input {
    border-color: $color-light-grey;
    height: 40px;
  }

  .input-error {
    border-color: $error-red !important;
  }

  .search-icon {
    position: absolute;
    right: 20px;
    top: 8px;
  }

  .text-danger {
    font-size: 12px;
    color: $error-red !important;
  }

  .input-info:hover + .info-message {
    display: block;
  }
}
