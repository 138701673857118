@import 'resources/styles/variables';

.delete-button {
  height: 24px;
  font-size: 12px;
  background-color: transparent;
  color: $battleship-grey;
  border: none;
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
}
