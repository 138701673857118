@import "resources/styles/variables";

.customTooltip {
    pointer-events: none;
    position: fixed;
    z-index: 1200;
    top: var(--top, auto);
    right: var(--right, auto);
    bottom: var(--bottom, auto);
    left: var(--left, auto);
    opacity: var(--opacity, 0);
    transition-property: opacity, transform;
    transition-timing-function: ease-out;
    transition-duration: 0.1s;
  }
  
  .tooltipContent {
    background: $black;
    color: $white;
    border-radius: 4px;
    font-size: 12px;
    line-height: 16px;
    padding: 6px 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
    max-width: 320px;
  }
  
  .tooltipArrow {
    position: absolute;
    display: flex;
    justify-content: center;
  }
  
  .tooltipArrow:before {
    content: '';
    width: 0;
    height: 0;
    overflow: hidden;
    border-color: transparent;
    border-width: 4px;
    border-style: solid;
  }
  
  [data-direction='top'] .tooltipArrow {
    top: 100%;
  
    &:before {
      border-top-color: $black;
    }
  }
  
  [data-direction='right'] .tooltipArrow {
    right: 100%;
  
    &:before {
      border-right-color: $black;
    }
  }
  
  [data-direction='bottom'] .tooltipArrow {
    bottom: 100%;
  
    &:before {
      border-bottom-color: $black;
    }
  }
  
  [data-direction='left'] .tooltipArrow {
    left: 100%;
  
    &:before {
      border-left-color: $black;
    }
  }
  
  [data-direction='top'] .tooltipArrow,
  [data-direction='bottom'] .tooltipArrow {
    left: 0;
    min-width: 20px;
    max-width: 100%;
    width: var(--arrow, 100%);
  }
  
  [data-direction='top'][data-inverse] .tooltipArrow,
  [data-direction='bottom'][data-inverse] .tooltipArrow {
    left: auto;
    right: 0;
  }
  
  [data-direction='left'] .tooltipArrow,
  [data-direction='right'] .tooltipArrow {
    flex-direction: column;
    top: 0;
    min-height: 20px;
    max-height: 100%;
    height: var(--arrow, 100%);
  }
  
  [data-direction='left'][data-inverse] .tooltipArrow,
  [data-direction='right'][data-inverse] .tooltipArrow {
    top: auto;
    bottom: 0;
  }
  
  