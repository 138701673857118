@import "resources/styles/variables";


.bigger-input-field{
    height: 69px !important;
}

.app-tabs {

    .Zmcc-tab-header {
      
 
      .tab {
        padding: 10px;
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        border: 1px solid rgba(224, 225, 221, 1);
        height: 42px;
        line-height: 11.04px;
      }
  
      .active {
        color: rgba(0, 136, 208, 1);
        background-color: #ffffff;
      }
    }
  }

  .active-tab-color{
    border: 1px solid #0088D0 !important;
  }

  .active-tab-icon-color {
    background: #0088D0;
    width: 20px;
    height: 20px;
    color: #FFFFFF;
    border-radius: 50px;
    text-align: center;
    font-size: 12px;
    line-height: 19.47px;
    font-weight: 600;
  }

  .tab-icon-and-name{
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .non-active-icon-color{
    background: #0088D0;
    width: 20px;
    height: 20px;
    color:#FFFFFF;
    border-radius: 50px;
    text-align: center;
    font-size: 12px;
    line-height: 19.47px;
    font-weight: 600;
  }

  .mandatory-fields-not-filled-color{
    background: #d80606;
    width: 24px;
    height: 24px;
    color:#000000;
    border-radius: 13px;
    text-align: center;
    font-size: 15px;
  }

  .delete-icon-name{
    font-size: 14px;
    color: #0088D0;
  }

  .border-for-todo-list{
   border-bottom: 1px solid #E0E1DD;
   word-wrap: break-word;
  }

  .title-heading{
    font-family: 'ZEISS Frutiger Next W07 Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
  }

.zmcc-title-heading{
  font-size: 12px;
  font-weight: 600;
  line-height: 14.47px;
  letter-spacing: 0px;
  text-align: left;
  background-color: rgba(248, 248, 248, 1);
  height: 34px;
  padding: 10px !important;
}

  .zmcc-trans-cancel-button{
    float: right;
    margin-top: -3.3%;
  }

  .zmcc-trans-cancel-button1{
    float: right;
    margin-top: -2.7%;
  }


  .zmcc-todo-list-font-style{
    font-size: 14px;
    font-weight: bold;
  }

  .applicationTab-input-box{
    width: 93%;
  }

  .zmcc-border-steps {
    border-bottom: 1px solid #eff0ee;
    margin-bottom: 25px;
  }

  .zmcc-form-title-heading{
    font-size: 14px;
    font-weight: 600;
    line-height: 16.88px;
    color: rgba(0, 0, 0, 1);
  }
  .warehouse-style{
    font-size: 16px;
    font-weight: 300;
    line-height: 28px;
    letter-spacing: 0px;
    // font-family: 'ZEISS Frutiger Next W07 Medium';
    color: #9A9B9C;
  }

  .zmcc-add-edit{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .save-cancel-next-button-adjust {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .save-next-button-alignment{
    display: flex;
    align-items: center;
  }

  .tabs-button-alignment{
    display: flex;
    gap: 50px;
    // justify-content: space-between;
  }

  .section-border{
    border-top: none;
    border-left: 1px soild rgba(248, 248, 248, 1);
    border-right: 1px soild rgba(248, 248, 248, 1);
    border-bottom: 1px soild rgba(248, 248, 248, 1);
  }

  .save-next-pev-button{
    width: 116px;
    height: 38px;
  }

  .demo-center-text{
    font-size: 12px;
    line-height: 14.47px;
    font-weight: 600;
  }

  .logistic-edit{
    border: none;
    background-color: white;
    margin-top: 5px;
  }

  .demo-attendee-title{
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }

  .demo-attendee-content{
    font-size: 14px;
    font-weight: 300;
    line-height: 29px;
  }

  .adjust-long-text{
    word-break: break-all;
  }

  .logisctic-checkbox-adjust{
    margin-top: 33px;
  }

  .border-for-todo{
    border-bottom: 1px solid #E0E1DD;
    margin-bottom: 18px;
    width: 36%;
  }

  .lmApplication-input-box-width{
    width: 49%;
  }

  .execute-button-widith{
    height: 38px;
    width: 136px;
  }

  .solved-by-edit{
    display: flex;
    gap: 10px;
    align-items: center;
  }