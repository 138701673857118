.filter-container {
    background-color: #ffffff !important;
    align-items: flex-end;
    margin-left: 40px;
    margin-right: 40px;
    padding: 20px;
    margin-top: -1px;
}

.button-container{
    // display: flex;
    flex:1;    
}
.button-container button {
    margin-right: 10px; /* Add space between buttons */
}

.active-homepage-filter{
    color: #0088d0;
    border-top: 2px solid #0088d0;
    align-items: center;
    font-weight: bold;
}

.non-active-homepage-filter{
    color: black;
    font-weight: lighter;
    align-items: center;
    font-weight: bold;
}