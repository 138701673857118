.modalPortal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;

  .modalOverlay {
    position: absolute;
    width: 100%;
    top: -100px;
    bottom: -100px;
    left: 0;
    opacity: 0.8;
    cursor: pointer;
    z-index: 1;
  }

  .modalContent {
    z-index: 2;
    outline: none;
  }
}
