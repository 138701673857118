.list {
  list-style: none;
  min-width: 264px;

  .list-item {
    padding: 12px;
    font-size: 16px;
    &:not(:last-child) {
      border-bottom: 1px solid #E9E9E9;
    }
  }

  .interactive {
    cursor: pointer;
  }
  
  .interactive:hover,
  .interactive:focus,
  .interactive:focus-within {
    background: #eff0ee;
  }
  
  .active,
  .active:focus,
  .active:focus-within,
  .active:hover {
    background: #39BBFF;
    color: white;
    font-weight: bold;
  }
}
