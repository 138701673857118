.app-datetime-picker-calendar {
  box-shadow: 2px 5px 16px -12px rgba(0, 0, 0, 0.75);
}

.react-datepicker {
  min-width: 445px !important;
  width: 100% !important;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.datetime-picker-icon {
  top: 27px !important;
  right: 15px !important;
  font-size: 16px;
}

.react-datepicker {
  font-family: 'FrutigerNextRegular' !important;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: none;
  border-radius: 0;
  display: inline-block;
  position: relative;
}

.react-datepicker__week {
  margin-top: 6px;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #000;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
  font-size: 16px;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  margin: 0 4.7px 0 4.7px;
}

.react-datepicker__day {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.react-datepicker__header {
  text-align: center;
  background-color: #fff;
  border-bottom: none;
  border-top-left-radius: none;
  padding: 8px 0;
  position: relative;
  height: 44px;
  // margin-bottom: 1em;
}

.react-datepicker__month {
  margin: 0.4rem;
  text-align: center;
  margin-top: 50px;
}

.react-datepicker__current-month {
  font-size: 18px;
  font-weight: normal;
  margin-top: 8px;
  margin-bottom: 1rem;
  font-weight: 600;
  .react-datepicker__day-name {
    margin-bottom: 10px;
  }
}

.react-datepicker__navigation {
  align-items: center;
  background: none;
  display: flex;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 2px;
  padding: 0;
  border: none;
  z-index: 1;
  height: 32px;
  width: 32px;
  text-indent: -999em;
  overflow: hidden;
  margin-top: 10px;
}

.react-datepicker__navigation-icon::before {
  border-color: #000;
  border-style: solid;
  border-width: 1px 1px 0 0;
  content: '';
  display: block;
  height: 9px;
  position: absolute;
  top: 6px;
  width: 9px;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 50px;
  background-color: #f0f0f0;
}

.react-datepicker__day--selected {
  background-color: #e1ff32;
  font-weight: bold;
  color: #000;
}

.react-datepicker__day--outside-month {
  color: #e0e1dd !important;
}

.react-datepicker__time-list-item {
  height: 30px;
  padding: 5px 10px;
  white-space: nowrap;
  font-size: 16px;
  margin-bottom: 10px;
}

.react-datepicker__header--time {
  border-bottom: 1px solid #e0e1dd;
}

.react-datepicker__time-container {
  border-left: 1px solid #e0e1dd;
}
.react-datepicker__time-list-item--selected {
  background-color: #e1ff32 !important;
  color: #000 !important;
}

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-top: 5px;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__navigation--previous {
  left: 10px;
  border-right-color: #ccc !important;
}

.react-datepicker__navigation--next {
  right: 93px !important;
  border-left-color: #ccc !important;
}

.react-datepicker__navigation--previous::before {
  border-color: #000;
  border-style: solid;
  border-width: 0 0 1.5px 1.5px;
  content: '';
  display: block;
  height: 6px;
  position: absolute;
  // top: 4px;
  width: 6px;
  rotate: 408deg;
}

.react-datepicker__navigation--next::before {
  border-color: #000;
  border-style: solid;
  border-width: 1.5px 1.5px 0 0;
  content: '';
  display: block;
  height: 6px;
  position: absolute;
  // top: 4px;
  width: 6px;
  rotate: 408deg;
  right: 24px;
}

.react-datepicker__time-container {
  height: auto !important;
}

.react-datepicker__time-list {
  max-height: 100% !important; /* Adjust if necessary */
}

.react-datepicker__month-container {
  height: auto !important;
}

.date-picker-input{
  cursor: pointer !important;
  color: #000 !important;
}
.date-picker-input::placeholder {
  color: #000 !important;
}
.react-datepicker__day--disabled{
  color: #ccc !important;
}