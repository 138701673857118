@import "resources/styles/variables";

.statistics-container {
  .tab-wrapper {
    margin: 32px 0;
  }

  .chart-wrapper {
    position: relative;
    height: 350px;
    width: 80%;
    margin-bottom: 62px;
  }
  @media (max-width: 1200px) {
    .chart-wrapper {
      width: 100%;
    }
  }

  .demos-statistic-wrapper {
    padding: 42px 0;
    border-top: 1px solid $color-light-grey;
    border-bottom: 1px solid $color-light-grey;
    margin-bottom: 62px;

    .subtitle {
      font-size: 16px;
      color: $color-cool-grey;
      white-space: pre-wrap;
    }
    .statistics-text {
      font-size: 30px;
      font-weight: bold;
    }
  }

  .satisfaction-chart-container {
    width: 100%;
    margin: 20px 0;

    .satisfaction-chart-wrapper {
      position: relative;
      margin-bottom: 20px;
    }
    .text-inside {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);
      font-size: 36px;
      color: $color-cerulean;
    }
    .custom-legend {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #262940;
      font-weight: bold;
      white-space: pre-wrap;
      text-align: center;
    }
  }

  .demos-wrapper {
    margin-bottom: 62px;
  }

  .activeKitConditionsChart-wrapper {
    display: flex;
    align-items: center;

    & > div {
      position: relative;
      height: 350px;
      display: flex;
      align-items: center;
    }

    .legend {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      cursor: pointer;
      color: $black;

      &:hover {
        color: $black;
      }

      .palette {
        width: 16px;
        height: 16px;
        margin-right: 10px;
      }
    }
  }

  .utilizedKits-wrapper {
    margin-bottom: 62px;

    .utilizedKits-table {
      table {
        th:nth-child(4) > div {
          justify-content: center;
        }
      }
    }
  }
}

.w-20 {
  display: flex;
  justify-content: center;
  width: 20%;
}

.custom-width{
  width:14.28% !important;
  max-width:14.28% !important;
  text-align: center;
}

.data-card{
  background: linear-gradient(#F8FFF8 80%, rgba(12, 148, 0, 0.1728));
  padding: 20px;
}
.data-card-count-decreased{
  background: linear-gradient(#FFF9F8 80%, rgba(201, 24, 3, 0.11728));
  padding: 20px;
}
.data-card-title{
  font-size: 14px;
  font-weight: 600;
  line-height: 16.88px;
}

.data-card-value{
  font-weight: 600;
  font-size: 30px;
  line-height: 36.18px;
}

.main-value-section{
  display: flex;
  gap: 15px;
  align-items: center;
}

.decreaded-percent-text-color{
  color: #C91803;
}

.increased-percent-text-color{
  color: #0C9400;
}

.session-by-visitor{
  background-color: #F9FBFF;
}

.legend-adjust{
  height: 13px;
  width: 13px;
  border-radius: 50px;
}

.loans-content-{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: '20px 0';
}

.loan-sub-content{
   display: flex !important;
   align-items: center !important;
   margin-bottom: 5px !important;
}

.bar-count-style{
  flex: 1 1 0%;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.bar-color{
  background-color: #97B3E7;
  height: 20px;
}

.request-loan-section{
  width: 50%;
  background-color: #F9FBFF;
  padding: 13px;
}

.adjust-loan-shipped-created{
  display: flex;
  gap: 40px;
}

.bar-chart-pie-position-alignment{
  display: flex;
  width: 100%;
  gap: 20px;
}

.adjust-card-data{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 20px 0px 0px;
  gap: 20px;
  justify-content: space-between;
}

.set-caed-height-width{
  width: 500px;
  height: 146.27px;
}

.set-loan-businessUnit-font{
  font-size: 14px;
  font-weight: 300;
  line-height: 16px;
  width: 21%;
}

.set-loans-businessUnit-count-font{
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  width: 21%;
  margin-left: 10px;
}

.no-data-text{
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

