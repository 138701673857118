@import "resources/styles/variables";

.app-tabs {
  .tab-header {
    background-color: $color-off-white;

    .tab {
      padding: 16px;
      font-weight: normal;
      font-size: 16px;
      position: relative; /* Ensure consistent positioning */

      &.active {
        color: #000000;
        background-color: #ffffff;
      }

      &.active-new-ui {
        color: #0088D0;
        background-color: #ffffff;
        border-top: 3px solid #0088D0;
        margin-top: -3px; /* Offset the top margin to compensate for border */
      }

    }
  }
  .new-ui-tab-header{
    box-sizing: border-box;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .tab {
      padding: 10px;
      width: fit-content;
      height: 37px;
      margin-right: 20px; 
      font-size: 14px;
      font-weight: 600;
      color:#000000;
      line-height: 16.88px;
    }
  }

  .new-ui-tab-pane {
    border-top: 3px solid transparent; /* Ensure consistent border-top for all tabs */
  }


}

.app-tabs .tab-header .cart-badge {
  display: inline-block;
  width: 20px;
  height: 20px;
  color: #ffffff;
  font-size: 12px;
  border-radius: 50%;
  text-align: center;
  padding-top: 2px;
  font-weight: bold;
  background-color: #0088D0;
}
