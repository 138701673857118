.account-statement-table {
  .custom-table table td, .custom-table table th {
    font-size: 14px;

    p {
      white-space: pre-line;
    }
  }
}

.kpi-table-container  {
  tr th:not(:first-child) > div {
    justify-content: center;
  }

  tr td:not(:first-child) {
    text-align: center;
  }
}