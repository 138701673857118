@import "resources/styles/variables";
@import "resources/styles/mixins";

.calender-view-triangle {
  position: absolute;
  top: 42px;
  left: 160px;
  width: 0;
  height: 0;
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
  border-bottom: 12px solid #fff;
  z-index: 99999;
}

.date-picker-label {
  font-size: 14px !important;
  font-weight: 300;
  line-height: 12.88px;
  color: rgba(0, 0, 0, 1);
}
.date-picker-additional-label {
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: normal;
  color: $color-cool-grey;
}
.date-input-container {
  position: relative;
}

.date-picker-footer-label {
  position: absolute;
  left: 0;
  right: 0;
  overflow: hidden;
  top: 100%;
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: normal;
  color: $color-cool-grey;
  white-space: nowrap;
}

.optional-text {
  color: $color-cool-grey;
}

.datepicker-component-container {
  border: 0;

  .react-calendar__tile:disabled {
    background-color: #fff;
  }

  .react-calendar {
    .react-calendar__navigation__next2-button {
      display: none;
    }

    .react-calendar__navigation__prev2-button {
      display: none;
    }
  }

  .react-calendar__decade-view__years {

    padding: 8px;

    .react-calendar__decade-view__years__year {
      flex-basis: unset !important;
      height: 64px;
      width: 64px;
      margin: 8px;
      padding-top: 25px;
    }

    .react-calendar__tile--hasActive {
      background-color: $color-off-yellow;
      font-weight: bold;
      color: #000;
    }

    .react-calendar__tile {
      //height: 90px;
      border-radius: 50%;
      font-size: 16px;
    }
  }

  .react-calendar__year-view {
    .react-calendar__tile {
      //height: 90px;
      border-radius: 50%;
      font-size: 16px;
    }

    .react-calendar__year-view__months {
      justify-content: center;
      padding-bottom: 16px;
    }

    .react-calendar__tile--hasActive {
      background-color: $color-off-yellow;
      font-weight: bold;
      color: #000;
    }

    .react-calendar__year-view__months__month {
      border-radius: 50%;
      flex-basis: unset !important;
      height: 64px;
      width: 64px;
      display: flex;
      margin: 0 10px 12px 10px;
      padding-top: 25px;
      align-items: center;
      justify-content: center;
      font-size: 13px;
    }
  }

  .react-calendar__month-view__weekdays {
    .react-calendar__month-view__weekdays__weekday {
      abbr {
        text-transform: capitalize;
        text-decoration: none;
      }
    }
  }

  .react-calendar__month-view__days {
    padding: 0 2px 16px 2px;

    .react-calendar__tile {
      //background-color: red;
      border-radius: 45px;
      font-size: 16px;
    }

    .react-calendar__tile--now {
      color: $color-blue;
      font-weight: bold;
    }

    .react-calendar__tile--active {
      background-color: $color-off-yellow;
      font-weight: bold;
      color: #000;
    }

    .react-calendar__month-view__days__day {
      flex-basis: unset !important;
      height: 40px;
      width: 40px;
      border-radius: 20px;
      margin: 0 4.7px 0 4.7px;
    }

    .react-calendar__month-view__days__day--weekend {
      color: unset;
    }

    .react-calendar__month-view__days__day--neighboringMonth {
      color: #e0e1dd;
    }

    button:disabled {
      color: #e0e1dd;
    }
  }

  button:disabled {
    color: #e0e1dd;
  }
}

.react-date-picker__calendar {
  z-index: 9999;

  .datepicker-calendar-container {
    border: 0;
    margin-top: 4px !important;

    .react-calendar__navigation button[disabled] {
      background-color: #fff;
    }
  }

}

.app-datepicker {
  background-color: #ffffff;
  position: relative;
  border: solid 1px $color-light-grey;
  height: 40px;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  .datepicker-view {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #fff;
    z-index: 1;
    padding-left: 12px;
    padding-right: 12px;
    span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden
    }
  }
}

.disabled {
  background-color: #e0e1dd !important;
}

.datepicker-custom-container {
  background-color: #fff;
}

.calendar-shadow {
  @include createBoxShadow(2px, 5px, 15px, -7px, rgba(0, 0, 0, 0.75));
}

.mandatory-asterik {
  font-weight: bolder;
  color: red;
  font-size: 15px;
}