@import "resources/styles/variables";

.loan-list-date-picker {
  .app-datepicker {
    border: none !important;

    .current-date {
      margin-left: 8px !important;
      font-size: 16px;
      font-weight: 300;
    }

    .datepicker-view {
      padding-left: 0;
    }

    .date-picker-label {
      color: $color-cool-grey; 
      margin-bottom: 1px !important;
    }
  }
}
