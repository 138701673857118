@import "resources/styles/variables";

.add-button {
  margin-top: 18px;
}

.stock-controllers-list {

  .stock-controller-list-item {
    border: solid 1px rgba(232, 232, 232, 1);
    padding: 8px 10px 8px 10px !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    border-radius: 4px;

    .delete-button {
      background: none;
      border: none;
      color: $color-blue;
      display: flex;
      align-items: center;
    }
  }
}

.adjust-add-location-manager-btn{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 17px;
  height: 38px;
  font-size: 14px;
  line-height: 16.94px;
  margin-top: 19.3px !important;
}

