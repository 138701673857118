@import "resources/styles/variables.scss";

.add-button {
  margin-top: 18px;
}

.stock-controllers-list {
  border-top: solid 1px $color-off-white;
  margin-top: 15px;

  .stock-controller-list-item {
    border-bottom: solid 1px $color-off-white;
    padding: 6px 0;
  }
}

.fix-column {
  float: left;
}

.thead {
  width: unset;
  border-top: 1px solid #dee2e6;
  height: 2.5em;
  white-space: nowrap;
}

.thead > span {
  display: inline-block;
  vertical-align: top;
  padding: 10px;
  min-width: 9em;
  text-align: center;
  font-size: unset;
}

.trow {
  white-space: nowrap;
}

.trow .email-messages-checkbox {
  width: unset;
  min-width: 9em;
  .newui-checkmark {
    left: calc(50% - 12px);
  }
}

.trow > span {
  border-top: 1px solid #dee2e6;
  display: inline-block;
  width: 29em;
  height: 2em;
  padding-top: 0.5em !important;
  padding-bottom: 0.3em !important;
}

.tbody {
  //max-height: 25em;
  overflow-y: hidden;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.fix-column > .tbody {
  overflow: hidden;
}

.rest-columns {
  width: 100%;
}

.rest-columns > .thead {
  overflow: hidden;
}

.rest-columns .tbody .trow:last-child {
  height: 60px;
}

::-webkit-scrollbar {
  height: 3px !important;
}

.message-container--no-border {
  border-bottom: none !important;
}

.categoryText{
  width: unset;
  border-top: 1px solid #dee2e6;
  
  white-space: nowrap;
  color: #000;
  font-size: 20px;
  font-weight: bold;
}
.categoryText > span {
  display: inline-block;
  vertical-align: top;
  padding: 10px 0 10px 0;
  min-width: 9em;
  font-size: unset;
  height:2em;
}

.adjust-email-msg-checkbox{
  justify-content: center;
}
