@import 'resources/styles/variables';

.blue-bg-message {
  background-color: $color-blue;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 12px;
  font-weight: bold;
  color: white;
  a {
    color: white;
    &:hover {
      color: white;
      text-decoration: underline !important;
    }
  }
}

.color-blue {
  color: $color-blue;
}
.transaction-details-button {
  border: none;
  background-color: transparent;
  span {
    color: $color-blue;
  }
}
.br-w-0 {
  border-right: 0 !important;
}

.transaction-details-form-container {
  // border-bottom: 1px solid $color-light-grey;
  .transaction-details-form {
    display: flex;
    flex: 1;
  }
  .transaction-details-date-input {
    display: flex;
    flex: 1;
  }
  .transaction-details-date-container {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
}
.edited-by-text {
  font-size: 12px;
}
.save-dates-button-container {
  max-width: 120px;
  width: 94%;
  margin-bottom: 1rem;
}

.adjust-save-dates-button-container {
  max-width: 141px;
  width: 94%;
  margin-bottom: 1rem;
}

.transaction-details-table {
  margin-bottom: 0 !important;
  tbody {
    tr {
      line-height: 24px;
      font-family: $font-weight-regular;
      td:first-of-type {
        font-weight: bold;
        white-space: nowrap;
        font-family: $font-weight-bold !important;
        padding-right: 10px;
      }
      td:nth-of-type(2) {
        width: 100%;
      }
      td {
        padding: 0.75em 15px 0.75em 0;
        vertical-align: middle;
      }
      .column-vertical-top {
        vertical-align: top;
      }
      &.small-height {
        td {
          padding: 0em 15px 0.25em 0;
        }
      }
      &.smaller-height {
        td {
          padding: 0.5em 15px 0.5em 0;
        }
      }
      .vertical-middle {
        vertical-align: middle;
      }
    }
  }
}

.return-goods-container {
  background-color: $color-off-white;
  padding: 15px;
}

.components-text {
  white-space: pre-line;
}

.transaction-details-title {
  font-size: 20px;
  font-weight: bold;
}

.c2c-box {
  border: 1px solid $color-off-white;
  padding: 20px 15px;
  &.c2c-box-gray {
    background-color: $color-off-white;
  }

  button {
    font-size: 12px !important;
    font-family: $font-weight-bold !important;
    height: auto !important;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.section-title {
  font-size: 12px;
  font-weight: 600;
  line-height: 14.47px;
}
.message-load-more-button {
  min-width: 300px;
}
.error-message {
  color: #cc0000;
  font-size: 14px;
}

.adjust-dates-content-container {
  position: relative;
  .adjust-dates-disable-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
  }
}

.disable-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}

.change-log-item {
  padding: 10px 0;
  border-bottom: 1px solid $color-light-grey;
  &.no-border {
    border-bottom: none;
  }
}
.change-history-time-text {
  font-size: 14px !important;
  color: $color-dark-gray;
}

.c2c-btn-bordered {
  display: flex;
  align-items: center;
  // border: solid 1px $color-blue;
  font-size: 12px;
  line-height: 12px;
  color: $color-blue;
  font-family: $font-weight-bold;
  padding: 0 10px;
  width: fit-content;
}

.show-all-messages-button {
  width: auto;
  margin-top: 16px;
  padding-left: 0;
  font-weight: lighter;
}

.modal-width-for-editMaterial {
  max-width: 75vw !important;
}

.modal-width-for-addMaterial {
  max-width: 60vw !important;
}

.add-delete-button {
  margin-top: 6px;
}

.description-text {
  margin-right: 39px;
}


.modal-width-for-pickRequest {
  max-width: 70vw !important;
}
.pickRequest-button {
  width: auto;
  margin-top: 0px;
  padding-left: 0;
  font-weight: lighter;
  margin-left: 6px;
}

.npsText{
  text-align: center;
}

@media (min-width: 576px){
.create-new-loanorder {
    max-width: 70vw !important;
    margin: 1.75rem auto;
}
}

.sap-erp-table {
  width: 60% !important;
}

.sap-erp-table-checkbox {
  border-radius: 50% !important;
}
.material-number {
  overflow-wrap: break-word;
}
.return-checkbox {
  margin-top: -33px;
}

.fix-delete {
  vertical-align: initial;
}

.maintenanceEdit{
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #F8F8F8;
  height: 39px;
  padding: 10px;
}

.medUsCutomers{
  overflow: scroll;
  height: 600px;
}

.prevTransaction {
  position: relative;
  display: inline-block;
}
.nextTransaction{
  position: relative;
  display: inline-block;
}
.hide {
  font-size: 15px;
  visibility: hidden;
  width: 200%;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  background: #333333 !important;
  color: white;
  white-space: pre-line;
  margin-top: 100%;
  font-family: 'FrutigerNextRegular';
  font-style: normal;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}
    
.prevTransaction:hover + .hide {
  visibility: visible;
}

.nextTransaction:hover + .hide {
  visibility: visible;
}

.updatePrevTransaction {
  position: relative;
  display: inline-block;
}
.updateNextTransaction{
  position: relative;
  display: inline-block;
}
.display {
  font-size: 15px;
  visibility: hidden;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  background: #333333 !important;
  color: white;
  white-space: pre-line;
  font-family: 'FrutigerNextRegular';
  font-style: normal;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}
    
.updatePrevTransaction:hover + .display {
  visibility: visible;
}

.updateNextTransaction:hover + .display {
  visibility: visible;
}

.submit-maintainceCheckList-button{
  display: flex;
  justify-content: end;
  margin-right: 1%;
  margin-block: 1rem;
}

.checkList-form-lable{
  font-weight: bold;
}

.trans-title{
  font-size: 30px;
  font-weight: 600;
  line-height: 36.18px;
  color: #000000;
}


.title-section{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 00px;
}

.date-shadow-effect{
 box-shadow: 0px 4px 16px 0px #0000001A;
}

.message-tab-button-adjust{
  display: flex;
  gap: 15px;
}

.non-active-message-button{
  color: #000000;
  border: 1px solid #E0E1DD;
  font-size: 12px;
  line-height: 11.04px;
  height: 31px;
  font-weight: 300;
  background-color: #fff;
  padding: 10px;
}

.active-message-button{
  color: #0088D0;
  border: 1px solid #0088D0;
  font-size: 12px;
  line-height: 11.04px;
  height: 31px;
  font-weight: 300;
  background-color: #fff;
  padding: 10px;
}

.message-comment-data{
  font-size: 12px;
  font-weight: 600;
  line-height: 14.47px;
  text-align: left;
}

.section-border{
  border: 1px solid #F8F8F8;
}

.checklist-filed-label{
  font-size: 14px;
  font-weight: 600;
  line-height: 16.88px;
}

.checkList-value{
  font-size: 14px;
  font-weight: 300;
  line-height: 15px;
  margin-top: 6px;
}

.button-without-border{
  border: none !important;
}

.new-ui-text-util-butons{
  font-size: 14px;
  font-weight: 600;
  line-height: 16.88px;
}

.maintanance-checklist-btn{
  width:20vw;
}

.label-important::after{
  content: " *";
  color: red;
  font-weight: bolder;
  font-size: 15px;
}

.attendee-border{
  border: 1px solid $color-light-grey;
  margin: 1px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}