@import "resources/styles/variables";

.delivery-address {
  .page-title {
    font-size: 36px;
    font-weight: bold;
  }

  .page-sub-header {
    padding-bottom: 24px !important;
  }

  .create-new-button {
    margin-bottom: 24px;
  }

  .my-delivery-title {
    font-size: 30px;
  }

  .edit-button {
    width: auto;
    border: none;
    margin-top: 16px;
    color: $color-blue;
    background: transparent;
    font-weight: normal;
    padding: 0;
  }

  .delete-button {
    width: auto;
    color: $color-blue;
    padding: 0;
    margin-left: 10px;
  }
}
