.section-title-heading {
  font-size: 12px;
  font-weight: 600;
  line-height: 14.47px;
  letter-spacing: 0px;
  text-align: left;
  background-color: rgba(248, 248, 248, 1);
  height: 34px;
  padding: 10px !important;
}

.section-border-kit {
  border: 1px solid #f8f8f8;
}
