@import 'resources/styles/variables';
@import 'modules/Kits/KitDetails/kitDetailsStyles.scss';
@import 'modules/Kits//KitsList/KitsList.styles.scss';
@import 'modules/Stock/StocksList/stocksStyles.scss';
@import 'modules/KitLoan/kitLoanStyles.scss';
@import 'modules/Booking/bookingStyles.scss';

@font-face {
  font-family: $font-weight-bold;
  src: url('resources/fonts/ZEISSFrutigerNextW07-Bold.woff2');
}

@font-face {
  font-family: $font-weight-regular;
  src: url('resources/fonts/ZEISSFrutigerNextW07-Regular.woff2');
}

body {
  margin: 0;
  padding: 0;
  font-family: $font-weight-regular, $font-weight-bold !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px !important;

  &[is-open-modal='true'] {
    overflow: hidden;
  }
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
ul {
  margin: 0 !important;
  padding: 0 !important;
}

.cursor-pointer {
  cursor: pointer;
}

a {
  text-decoration: none !important;

  &hover {
    text-decoration: none !important;
  }
}

button,
input {
  outline: none !important;
  box-shadow: none !important;

  &focus {
    border-color: transparent !important;
    outline: none !important;
    box-shadow: none !important;
  }
}

.text-italic {
  font-style: italic;
}

.bg-off-white {
  background-color: $color-off-white;
}

.label-container {
  font-size: 14px !important;
  font-weight: 300;
  line-height: 12.88px;
  color: rgba(0, 0, 0, 1);
  margin-bottom: 7px;

  .optional-text {
    color: $color-cool-grey;
  }
}

.error-block {
  color: $color-red;
  font-size: 12px;
}

.container-fluid {
  padding-right: 32px !important;
  padding-left: 32px !important;
}

.page-title {
  font-size: 36px;
  font-weight: bold;
  color: #000;
  margin-bottom: 24px !important;
}

.kits-page-title {
  font-size: 30px;
  font-weight: bold;
  color: #000;
  margin-bottom: 24px !important;
}

.form-control {
  border-color: $color-light-grey !important;
}

.form-title {
  color: #000;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 16px !important;
}

.sub-title {
  font-size: 16px;
  line-height: 1.5;
}

.header-form-title {
  color: #000;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 16px !important;
}

.form-container {
  margin-top: 32px;
}

.form-section-title {
  font-size: 30px;
  font-weight: 300;
  line-height: 1.07;
  margin-bottom: 24px !important;
}

.input-info {
  height: 16px;
  width: 16px;
  font-size: 10px;
  text-align: center;
  margin-left: 10px !important;
  border-radius: 50%;
  border: solid 1px $color-cool-grey;
  color: $color-cool-grey;
}

.info-message {
  width: 400px;
  background-color: #000000;
  color: #ffffff;
  display: none;
  top: -40px;
  z-index: 999;
  padding: 5px;
}

.text-small {
  font-size: 12px;
}

// Loader CSS
.loader {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  overflow: visible;
  background: rgba(255, 255, 255, 0.6) url('resources/icons/spinner.svg') no-repeat center center;
  background-size: 4%;
}

.global-loader {
  @extend .loader;
  background: #ffffff url('resources/icons/spinner.svg') no-repeat center center;
}

.view-type-buttons {
  display: flex;
  align-items: center;
  button {
    background: none;
    border: none;
  }
}

.font-bold {
  font-family: $font-weight-bold;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e0e1dd !important;
}

::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 0;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media (max-width: 1920px) {
  .modal-dialog {
    margin-top: 160px !important;
  }
}

.new-filter-chips-container {
  background-color: $filter-bg-background;
  line-height: 12px;
  font-size: 14px;
  color: #000;
  padding: 0.4em 0.6em;
  margin-right: 0.4em;
  font-weight: 300px;
  flex-shrink: 0;
  span {
    font-size: 18px;
    line-height: 18px;
    margin-left: 8px;
    cursor: pointer;
  }
}

.filter-chips-container {
  background-color: $color-blue;
  line-height: 12px;
  font-size: 12px;
  color: white;
  padding: 0.4em 0.6em;
  margin-right: 0.4em;
  font-weight: bold;
  flex-shrink: 0;
  span {
    font-size: 18px;
    line-height: 18px;
    margin-left: 8px;
    cursor: pointer;
  }
}
.filter-chips-delete-all {
  color: $color-blue;
  line-height: 16px;
  font-size: 16px;
  cursor: pointer;
  margin-left: 5px;
  flex-shrink: 0;
}
@media (min-width: 576px) {
  .map-modal {
    max-width: 80vw !important;
  }
}

.map-side-panel {
  z-index: 9900;
  display: flex;
  flex-direction: column;
  background-color: #eff0ee;
  height: 55vh;
  overflow: auto;
  transition: all 0.6s ease;
}

.map-side-panel-header {
  background-color: #db4437;
  color: white;
  padding: 8px;
  font-size: 16px;
  font-weight: 600;
}

@keyframes inAnimation {
  0% {
    transform: translateX(-10%);
    visibility: hidden;
  }
  100% {
    transform: translateX(0%);
    visibility: visible;
  }
}

@keyframes outAnimation {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-10%);
    visibility: hidden;
  }
}

.kits-picture-upload {
  width: 100%;
}

.info-helper-message{
  color: $color-blue;
  font-size: 12px;
}