@import "resources/styles/variables";

.doughnut-chart-container {
  margin: 20px 0;

  .chart-wrapper {
    position: relative;
    margin-bottom: 30px;
  }
  .text-inside {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    font-size: 36px;
  }
  .delivered {
    color: $color-deep-blue;
  }
  .total {
    color: $color-cool-grey;
  }
  .custom-legend {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .palette {
    height: 16px;
    width: 16px;
    background: $color-deep-blue;
    margin-right: 10px;
  }
}

.stock-dashboard-container {
  .row div:first-of-type .block-with-border {
    position: relative;
  
    &::before {
      content: '';
      position: absolute;
      background-color: $color-whisper;
      width: 1px;
      right: -16px;
      height: calc(100% - 32px);
    }
  }

  .month-wrapper {
    margin: 42px 0;

    .subtitle {
      font-size: 16px;
      color: #6F6F6F;
    }
    .statistics-text {
      font-size: 30px;
      font-weight: bold;
    }
  }

  .counter-wrapper {
    display: flex;
    justify-content: flex-end;
    margin: 20px 0;
    color: $color-oslo-gray;
  }
}

.fullScreen {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .fullScreen-container {
    height: 100%;
    width: 100%;
    background-color: $white;
  }
  .fullScreen-content  {
    height: 100%;
    overflow: auto;
    padding-bottom: 48px;
  }
}

@media (min-width: 2560px) {
  .fullScreen {
    .doughnut-chart-container {
      .text-inside {
        font-size: 2em;
      }
      .text-inside {
        .delivered {
          font-size: 4em;
        }
        .total {
          font-size: 4em;
        }
      }
      .palette {
        height: 32px;
        width: 32px;
        margin-right: 20px;
      }
      .custom-legend > span {
        font-size: 1.5em;
      }
    }

    .stock-dashboard-container {
      .month-wrapper {
        .month-title  {
          font-size: 2em;
        }
        .subtitle {
          font-size: 2em;
        }
        .statistics-text {
          font-size: 2em;
        }
      }

      .counter-text {
        font-size: 2em;
      }

      .text-center {
        font-size: 2em;
      }

      .progress-bar-container {
        .title {
          font-size: 2em;
        }
        .indicators {
          font-size: 2em;
        }
      }
    }

    .app-tabs .tab-header .tab {
      font-size: 2em;
    }
  }
}