@import "resources/styles/variables";

.booking {
  .booking-title {
    font-size: 30px;
    line-height: 36.18px;
    font-weight: 600;
    color: #000;
  }

  .booking-table {
    table {
      thead th {
        vertical-align: baseline;
      }
      th > div {
        height: auto;
      }
    }

    tr th:not(:last-child, :first-child) {
        width: 160px;
    }

    tr th:first-child {
        width: 100px;
    }
  }

  .react-date-picker {
    width: 100%;
    height: 100%;

    .react-date-picker__wrapper {
      width: 100%;
    }
  }

  .react-date-picker__clear-button {
    padding: 3px;
    z-index: 1000;
  }

  .date-picker-label {
    height: 18px;
  }

  .delay-tooltip {
    position: absolute;
    display: none;
    top: unset;
    min-width: 200px;
    background-color: #EFF0EE;
    color: #000000;
    display: none;
    z-index: 999;
    padding: 5px 40px 5px 5px;
    box-shadow: 0px 4px 16px 0px #0000000D;
  }

  td .info-icon:focus + .delay-tooltip  {
    display: block;
    margin-top: 15px;
  }

  .delivery-tr {
    max-width: 90px;
    display: flex;
    justify-content: space-between;
  }

  .team-button {
    display: flex;
    justify-content: center;
    width: 40px;
    padding: 0;
    margin-left: 0;
  }

  .delete-button {
    color: #0088d0;
  }

  .team-container {
    display: flex;
    margin-right: 16px;
  }

  @mixin member-team {
    width: 40px;
    height: 40px;
    font-size: 16px;
    text-align: center;
    line-height: 40px;
    margin-right: 6px;
    color: #9a9b9c;
  }

  .member-block {
    @include member-team;
    position: relative;
    border: 1px solid #e0e1dd;
    cursor: pointer;

    &:hover .tooltiptext {
      display: block;
    }
  }

  .member-gray-block {
    @include member-team;
    border: 0;
    background-color: #eff0ee;
  }

  .members-list {
    max-height: 340px;
    overflow-y: auto;
    margin: 24px -15px;
    padding: 0 15px;
    border-top: 1px solid #e0e1dd;
    border-bottom: 1px solid #e0e1dd;

    .member-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 0;
      &:not(:last-child) {
        border-bottom: 1px solid #e0e1dd;
      }
    }
  }

  .name-container {
    margin-left: 16px;

    .name-text {
      font-size: 16px;
      color: #000;
    }

    .email-text {
      font-size: 12px;
      color: #9a9b9c;
    }
  }

  .subject {
    font-size: 12px;
    margin: 18px 0;
  }

  .tooltiptext {
    position: absolute;
    display: none;
    z-index: 1000;
    width: auto;
    height: 18px;
    line-height: 12px;
    bottom: 50px;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 4px 5px;
    color: #fff;
    opacity: 0.8;
    background-color: #000;
    font-size: 12px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
    text-align: center;
    white-space: nowrap;

    i {
      position: absolute;
      bottom: -50%;
      left: 50%;
      margin-left: -10px;
      width: 20px;
      height: 10px;
      overflow: hidden;

      &::after {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        background-color: #000;
        box-shadow: 0 1px 8px rgba(61, 32, 32, 0.5);
      }
    }
  }
}

.delay-text {
  color: $color-red;
}

@media (min-width: 992px){
.modal-lg-cancel {
    max-width: 450px;
}
}

.launch-filter-text-color{
  color: black;
}

.transaction-actions-btns{
  display: flex;
  flex-direction: row-reverse !important;
}

.transaction-actions-btns.action-danger{
  color: #C91803;
}
.type-button{
  border: none;
  font-size: 14px;
  font-weight: 600;
  background-color: #fff;
  padding: 12px;
}

.active-transaction-type{
  border-top: 3px solid #0088D0;
  border-left: none;
  border-right: none;
  border-bottom: none;
  color: #0088D0;
  background-color: #fff;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.88px;
}

.adjust-trans-type-button{
  display: flex;
  align-items: flex-start;
  gap: 15px;
  align-items: center;
}

.adjust-hideFilter-and-type{
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.hideFilter-button{
  color: #0088D0;
}

.new-filter-body{
  box-shadow: 0px 4px 16px 0px #0000001A;
  padding: 18px;
  margin-bottom: 2rem;
  margin-top: 2rem;
}


.dispaly-status{
  display: none;
  position: relative;
}

.dispaly-status{
  position: absolute;
  z-index: 999999;
  background-color: #EFF0EE;
  padding: 10px 40px 10px 10px;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
}

.show-info-onhover:hover {
  overflow: visible;
  cursor: pointer;
 .dispaly-status{
    display: block;
  }
}



