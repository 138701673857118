.carousel-container {
  // width: 800px;
  overflow: hidden; 
  display: flex;
  flex-direction: column;
  align-items: center;
  // background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 10px;
}

.carousel {
  display: flex;
  transition: transform 3s ease;
}

.carousel-inner {
  width: 100%;
  display: flex;
}

.carousel-inner .carousel-item {
  width: 100%;
  display: none;
  justify-content: center;
  align-items: center;
}

.carousel-inner .carousel-item.active {
display: flex;
}

.image-wrapper {
  width: 60vw; /* Set the fixed size */
  height: 400px; /* Set the fixed size */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Hide overflow */
  // background-color: #f0f0f0; /* Background color for the image wrapper */
}

.image-wrapper img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover; /* Ensure the image covers the given dimensions */
}

.title-container {
  margin: 20px;
}

.title-container h1 {
  font-weight: bolder;
  font-size: 36px;
}

.description-homepage-container {
  width: 140vh;
}

.description-homepage-container p {
  text-align: justify;
  font-weight: bold;
  font-size:16px;
  line-height: 24px;
}
