.subscriptions-container {
  .settings-button {
    border: none !important;
  }
  
  .settings-icon {
    margin-left: 0;
  }

  .notification-title {
    color: #0088d0;
    font-size: 18px;
    font-weight: 600;
  }

  .border-bottom-dark {
    border-bottom: 1px solid #9A9B9C;
  }
}
