.interfaceMonitoring-container {
  .search-field {
    border: solid 1px #e0e1dd;
    margin-top: 18px;
  }
  .loupe-icon {
    position: absolute;
    top: 26px;
    right: 20px;
  }
}
