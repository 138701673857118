@import "resources/styles/variables";

.single-file-list-view {
  font-size: 12px;
  border-top: solid 1px $color-light-grey;
  padding: 8px 0;

  &:last-child {
    margin-bottom: 24px;
    border-bottom: solid 1px $color-light-grey;
  }

  .file-description {
    .file-name {
      color: $color-blue;
    }

    .file-icon {
      margin-right: 8px;
    }
  }

  .file-info {
    color: $color-cool-grey;

    .size {
      margin-left: 28px !important;
      margin-right: 16px !important;
    }

    .delete-file-button {
      background: transparent;
      border: none;
    }
  }
}


.single-file-grid-view {
  border: solid 1px $color-off-white;
  padding: 16px;
  overflow: hidden;

  .file-image {
    height: 256px;

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }

  .file-details {
    margin-top: 10px;

    .file-name {
      color: #000;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .file-type {
      color: $color-cool-grey;
    }
  }

  .size {
    color: $color-cool-grey;
  }

  .delete-button {
    top: 16px;
    right: 10px;
    border: none;
    background: transparent;
  }
}

.file-list-container {

  .list {
    margin-bottom: 20px;
    max-height: 500px;
    overflow-y: auto;
  }
}