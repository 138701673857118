.new-for-loan-container {
    margin-top: 80px;
}
.frequently-borrowed-container {
    margin-top: 48px;
}

.stock-popup{
  max-width: 53vw !important;
}

.image-button-align{
  display: flex;
  align-items: center;
  gap: 3%;
}

.category-button-size{
  width: 20%;
}

.category-image-size{
  height: 70px;
  width: 100px;
}

.zmcc-crm-popup-width{
  max-width: 47vw !important;
}

.homepage-container{
  background: linear-gradient(to bottom,#4868B1,  #B4E6FC);
}

.welcome-image{
  width: 712px;
  height: 229px;
}

.welcome-popup{
  max-width: 770px !important;
  border-radius: 7px;
}

.welcome-heading-text{
  font-size: 24px;
  font-weight: 400;
  line-height: 29.05px;
}

.welcome-sub-heading-text{
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
}
.what-chnage{
  background-color: #E8E8E8;
  border-left: 3px solid #0088D0;
  border-radius: 6px;
}

.what-changed-text{
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}

.support-team-text-color{
  color: #0088D0;
  text-decoration: underline;
  font-weight: 600;
}

.welcomePopup-close-btn{
  width: 220px;
  height: 38px;
  margin-top: -10px;
}

.add-scroll-bar{
  height: 58vh;
  overflow: auto;
}

.adjust-width-para{
  width: 712px;
}

.vrwelcome-popup{
  max-width: 1000px !important;
}

.modal-dialog.vrwelcome-popup {
  background-image: url('../../resources/images/VrPopupImage.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 8px;
  position: relative;
  color: white;
  display: flex;
  justify-content: center; 
  align-items: center;
}

.modal-dialog.vrwelcome-popup::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(15, 14, 14, 0.689); 
  z-index: 1;
}


.vrmodel-content{
  background-color: transparent;
  border: none;
  position: relative;
  z-index: 2; 
  width: 100%; 
}


.vr-popup-header {
  text-align: left; 
  font-size: 19px;
  color: white;
  line-height: 1.6; 
  padding: 10px; 
  z-index: 2;
  margin: 0 auto;
  margin-left: 20px !important;
  font-weight: 520;
  padding-top:20px;

}

.darkblue-text {
  color: #1c66fb; 
  font-weight: bold;
}

.lightgreen-text {
  color: #427ef8; 
  font-weight: bold !important;
  text-decoration: underline;
}

.lightgreen-text:hover {
  color: #1c66fb;
}

.exciting-header{
  font-size:21px !important;
  font-weight: 600;
}

.vr-popup-link {
  text-decoration: underline !important;
  text-decoration-color: rgb(57, 104, 245) !important;
}