@import 'variables';

@mixin createBoxShadow($horizontal, $vertical, $blur, $spread, $color) {
  -webkit-box-shadow: $horizontal $vertical $blur $spread $color;
  -moz-box-shadow: $horizontal $vertical $blur $spread $color;
  box-shadow: $horizontal $vertical $blur $spread $color;
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}
