@import 'resources/styles/variables';

.input-wrapper {
  position: relative;
  width: 256px;

  @media (max-width: 768px) { 
    width: 100%;
  }
}

.stock-title {
  font-size: 36px;
  line-height: 1.11;
  font-weight: bold;
  color: #000;
}

.filter-wrapper {
  background-color: $color-off-white;
  padding: 24px 0;
  margin-bottom: 20px;
}

.plus-icon {
  margin-left: 12px;
}

.request-stock-button {
  background-color: transparent !important;
  color: $color-blue !important;
  border: solid 1px $color-blue !important;
  font-size: 16px !important;
  font-weight: bold !important;
  height: 40px !important;
  padding: 6px 16px 0 16px !important;
}

.small-blue-button {
  width: auto;
  font-size: 12px;
  font-weight: normal;
  padding: 0;
}

.stock-table {
  tr th:not(:last-child) {
    width: 160px;
  }
  
  tr th:nth-child(8) {
    width: 50px;
  }
}
