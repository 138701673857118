.steps-container {
  display: flex;
  list-style: none;
  margin-top: 15px !important;

  li {
    display: flex;
    align-items: center;
    margin-right: 5%;
    color: rgba(0, 0, 0, 0.3);
  }

  .active-step {
    color: #000;
  }

  .number-step {
    font-size: 48px;
    font-weight: bold;
    line-height: 1.17;
    letter-spacing: normal;
    margin-right: 10px;
  }

  .text-step {
    font-size: 24px;
    font-weight: 300;
    line-height: 1.67;
    letter-spacing: normal;
  }
  @media (max-width: 600px) {
    .number-step {
      font-size: 36px;
      font-weight: bold;
      line-height: 1.17;
      letter-spacing: normal;
      margin-right: 5px;
    }
  
    .text-step {
      font-size: 18px;
      font-weight: 300;
      line-height: 1.67;
      letter-spacing: normal;
    }  
  }
}

.border-steps {
  border-bottom: 1px solid #eff0ee;
  margin-bottom: 64px;
}
@media (max-width: 600px) {
  .border-steps {
    margin-bottom: 44px;
  } 
}

.new-verision-active-step{
  border-top: 3px solid #0088D0;
  color: #0088D0 !important;
  padding: 12px;
}

.active-font-size{
  font-size: 16px !important;
  line-height: 16.88px !important;
  font-weight: 600 !important;
}

