@import 'resources/styles/variables';

.custom-table {
  .table {
    font-size: 12px;
    color: #000;

    thead {
      border-bottom: 1px solid $color-off-white;
    }
  }

  .button-blue {
    display: flex;
    align-items: center;
    background-color: transparent;
    color: $color-blue;
    border: none;
  }

  table th > div {
    height: 22px;
  }

  .table > tbody > tr:not(.stripped-rows):nth-child(2n) > td{
    background-color: $color-off-white;
  }

  .table > tbody > .stripped-rows:nth-child(4n) > td,
  .table > tbody > .stripped-rows:nth-child(4n-1) > td {
    background-color: $color-off-white;
  }

  .hide {
    display: none
  }

  .show {
    display: table-row
  }

  table td, table th {
    padding: 8px !important;
    font-size: 12px;
  }

  .triangle-up {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 5px 5px;
    border-color: transparent transparent $color-light-grey transparent; 
    margin-bottom: 2px;
  }


  .triangle-up-calendar-filter {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 5px 5px;
    border-color: transparent transparent $color-light-grey transparent; 
    margin: 5px 0px 0px 0px;
  }

  .triangle-down {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 5px 5px;
    border-color: $color-light-grey transparent transparent transparent;
  }

  .triangle-down-calendar-filter {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 5px 5px;
    border-color: $color-light-grey transparent transparent transparent;
    margin: 5px 0px 0px 0px;
  }

  .active-triangle-up {
    border-color: transparent transparent $color-dark-gray transparent; 
  }

  .active-triangle-down {
    border-color: $color-dark-gray transparent transparent transparent;
  }

  &.child-table {
    .table > tbody > tr:not(.stripped-rows):nth-child(2n) > td{
      background-color: transparent;
    }
  
    .table > tbody > .stripped-rows:nth-child(4n) > td,
    .table > tbody > .stripped-rows:nth-child(4n-1) > td {
      background-color: transparent;
    }
    .table > tbody > tr {
      border-bottom: 1px solid $color-off-white;
    }
  }

  .custom-filter-dropdown {
    width:80px;
    font-weight: bold;
    border: none;
    color: #0088d0;
    margin-left: auto;
    margin: 0px !important;
    padding: 0px !important;
    margin-top: 'auto';
    margin-bottom: 'auto';
    cursor: pointer;
  }

  .sortBy {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto; 
    padding:0px; 
    font-weight: bold;
  }
}
