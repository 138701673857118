.calculation-container {
  .text-bold {
    font-weight: bold;
  }

  .calculation-wrapper {
    p, .input-field {
      flex: 1;
      text-align: end;
    }
  
    p:nth-child(3n+4), p:nth-child(1) {
      text-align: start;
    }
  }
}

.set-title-cursor-style{
  cursor: pointer;
}
