@import 'resources/styles/variables';

.off-canvas-menu {
  position: fixed;
  left: 0;
  top: 0;
  width: 320px;
  height: 100%;
  min-height: 100vh;
  background-color: $color-blue;
  color: #ffffff;
  z-index: 99999;
  transition: 0.6s all;

  a {
    color: #ffffff;
  }

  .back-button {
    color: #ffffff;
    margin-left: -16px;
    background-color: transparent;
    border-color: transparent;

    svg {
      transform: rotate(180deg);
    }
  }

  .modal-close {
    top: 10px;
    right: 16px;
  }

  .menu-item {
    border-bottom: solid 1px rgba(255, 255, 255, .10);
    padding-top: 8px;
    padding-bottom: 8px;

    .menu-link {
      padding: 11px 16px;

      &:active, &:hover {
        color: #ffffff;
      }
    }

    .active {
      border-left: solid 4px #ffffff;
      padding-left: 12px;
    }
  }

  .child-menu-title {
    font-size: 20px;
    font-weight: bold;
    padding-left: 16px;
  }

  .menu-with-child {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  .menu-header {
    padding: 16px;

    h2{
      font-size: 24px;
      font-weight: 300;
    }
  }

  .menu-footer {
    padding: 16px;

    .nav {
      .nav-item {
        padding-right: 16px;
        font-weight: 300;
      }
    }
  }
}
