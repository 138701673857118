.item {
    padding: 6px 16px;
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 20px;
    color: #000;
    outline: none;
    text-decoration: none;
    border-radius: 4px;
  }
  
  .interactive {
    cursor: pointer;
  }
  
  .interactive:hover,
  .interactive:focus,
  .interactive:focus-within {
    background: #eff0ee;
  }
  
  .active,
  .active:focus,
  .active:focus-within,
  .active:hover {
    background: #eff0ee;
  }
  
  .disabled,
  .disabled:focus,
  .disabled:focus-within,
  .disabled:hover {
    color: #000;
    background: transparent;
    cursor: default;
  }
  
  .title {
    padding: 2px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 600px;
  }
  
  .checkbox {
    margin-right: 12px;
    flex-shrink: 0;
  
    span:before {
      display: none;
    }
  }
  
  .rightDecorator {
    padding-left: 12px;
    margin-left: auto;
    margin-top: -6px;
    margin-bottom: -6px;
  }