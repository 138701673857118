@import "resources/styles/variables";

.loan-list-container {
  .loan-list-notice {
    .notice-text {
      margin-left: 8px;
      font-size: 12px;
    }
  }

  .create-more-booking-button {
    border: none;
    color: $color-blue;
    background-color: transparent;

    .create-more-booking-icon {
      transform: rotate(180deg);
    }
  }

  .loan-list-action {
    margin-top: 24px;
  }
}
