@import 'resources/styles/variables';

.saving {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.saving div {
  animation-name: blink;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  padding-right: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  div {
    width: 6px;
    height: 6px;
    border-radius: 3px;
    background-color: currentColor;
  }
}
.saving div:nth-child(2) {
  animation-delay: .2s;
}
.saving div:nth-child(3) {
  animation-delay: .4s;
}
@keyframes blink {
  0% {
    opacity: .2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: .2;
  }
}
