.modal-container {
  .modal-background {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .content {
    padding: 16px;
  } 

  .modal-title {
    font-size: 30px;
    font-weight: 300;
    margin-bottom: 16px !important;
  }

  .modal-close {
    top: 10px;
    right: 14px;
  }
}
