@import "resources/styles/variables";

$lightBorderColor: #bfd8fe;
$darkBorderColor: #141e8b;
$glowColor: #bfd8fe;


.loan-item-container.active-loan-item{
  padding: 0.75rem;
  border: 2px solid  $lightBorderColor;
  box-shadow: 0 0 10px $glowColor; 
  margin-top: 15px;
}

@keyframes borderAnimation {
  0% {
    border-color: $lightBorderColor;
    border: 2px solid  $lightBorderColor;
    box-shadow: 0 0 10px $glowColor; 
  }
  50% {
    border: 2px solid  $lightBorderColor;
    box-shadow: none; 
  }
  100% {
    border: 1px solid $lightBorderColor;
    border: 2px solid  $lightBorderColor;
  }
}

.loan-item-container {
  transition: border-color 0.5s ease; 
  &.active-loan-item {
    animation: borderAnimation 3s 3;
  }
}

.loan-item-container {
  padding: 24px 0;
  border-bottom: solid 1px $color-light-grey;

  .kit-image {
    width: 160px;
    height: 160px;
    margin-right: 32px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .kit-detail {
    // width: calc(100% - 200px);
    font-weight: 300;

    .kit-name {
      font-size: 30px;
    }

    .kit-number {
      color: #999999;
    }

    .kit-description {
      color: #000;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      //line-height: 16px;     /* fallback */
      max-height: 60px;      /* fallback */
      -webkit-line-clamp: 2; /* number of lines to show */
      -webkit-box-orient: vertical;
    }

    .display-kit {
      color: $color-blue;

      a, a:hover {
        color: $color-blue;
      }
    }

    .kit-actions {
      margin-top: 16px;

      .checkbox {
        margin-right: 32px;

        label {
          margin-bottom: 0 !important;
        }
      }

      .delete-kit {
        border: none;
        color: $color-blue;
        padding: 0;
        margin: 0;
        background-color: transparent;

        span {
          margin-left: 4px;
        }
      }
    }
  }
  .allow-reservation {
    color: red;
    font-size: 14px;
    opacity: 0.8;
  }
}
