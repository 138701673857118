.right-header {
  .app-datepicker {
    border: none !important;

    .current-date {
      margin-left: 8px !important;
      font-size: 16px;
      font-weight: bold;
    }
  }
}

.zmcc-date-switch-button {
  color: black;
  background-color: white;
  border: none;
  svg {
    transform: rotate(180deg);
  }
}

.zmcc-back-arrow-calendar{
  border: none;
  background-color: white;
}

.date-adjust{
  width: 168px;
}
