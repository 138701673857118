$color-cool-grey: #9a9b9c;
$color-blue: #0088d0;
$color-deep-blue: #111E92;
$color-dark-blue: #0b1f40;
$color-light-blue: #91b3eb;
$color-midle-blue: #8c92ac;
$color-off-white: #eff0ee;
$color-light-grey: #e0e1dd;
$color-whisper: #e9e9e9;
$color-off-yellow: #e1ff32;
$color-green: #4ab300;
$color-dark-green: #154d14;
$color-red: #ff1a00;
$error-red: #cc0000;
$color-saturated-gray: #575959;
$color-dark-gray: #737678;
$battleship-grey: #747678;
$color-astronaut: #273C75;
$color-torea-bay: #111E92;
$color-cerulean: #0097E6;
$color-pacific-blue: #39BBFF;
$color-malibu: #7BD1FF;
$color-limeade: #4AB300;
$color-blue-violet: #5758BB;
$color-java: #12CBC4;
$color-atlantis: #A3CB38;
$color-dairy-cream: #F8EFBA;
$color-san-marino: #40739E;
$color-oslo-gray: #9A9B9C;
$white: #fff;
$black: #000;
$light-white-blue: #97B3E7;
$navy-blue: #132387;
$filter-bg-background: #F1F6FF;
$apple-green: #63BA3C;
$pure-red: #FF0000;
$brilliant-blue: #057df5;
$medium-gray: #808080;


$desktop-width: 1024px;
$tablet-width: 768px;
$mobile-width: 425px;
$calendar-resource-bg: #0F1F3E;


$font-weight-bold: 'FrutigerNextBold';
$font-weight-regular: 'FrutigerNextRegular';

:export {
  whiteColor: $white;
  blackColor: $black;
  astronautColor: $color-astronaut;
  toreaBayColor: $color-torea-bay;
  ceruleanColor: $color-cerulean;
  malibuColor: $color-malibu;
  limeadeColor: $color-limeade;
  blueVioletColor: $color-blue-violet;
  javaColor: $color-java;
  atlantisColor: $color-atlantis;
  dairyCreamColor: $color-dairy-cream;
  sanMarinoColor: $color-san-marino;
  osloGrayColor: $color-oslo-gray;
  ceruleanColor: $color-cerulean;
  blueColor: $color-blue;
  deepBlueColor: $color-deep-blue;
  greenColor: $color-green;
  darkGreenColor: $color-dark-green;
  lightBlueSeesionColor: $light-white-blue;
  navyBlue: $navy-blue;
  redColor: $color-red;
  lightBlue: $color-light-blue;
  darkBlue: $color-dark-blue;
  battleshipGrey :$battleship-grey;
  redColor : $color-red;
  appleGreen: $apple-green;
  pureRed : $pure-red;
  brilliantBlue: $brilliant-blue;
  mediumGray: $medium-gray;
  calendarResourceBg: $calendar-resource-bg;
}
