@import 'resources/styles/variables';

.header-container {
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: solid 1px $color-off-white;
  background: linear-gradient(to bottom,#4868B1,  #4868B1);
  #main-nav {
    z-index: 999;
  }

  .top-menu {

    .nav-item {
      color: $color-cool-grey;
      margin-left: 32px;

      a {
        color: $color-cool-grey;
      }
    }
  }

  .navbar {
    .nav-item {
      font-size: 18px;
      color: $color-cool-grey !important;

      .nav-link {
        color: $color-cool-grey !important;
        padding: 24px 16px !important;
      }

      .active {
        color: $color-blue !important;
        border-bottom: solid 1px $color-blue;
        font-weight: bold;
      }

      .dropdown-item.active {
        color: #fff !important;
      }
    }
  }

  .user-menu {
    .nav-item {
      padding: 16px 8px;
      position: relative;

      img {
        height: 23px;
        width: 23px;
      }
      .nav-link {
        color: $color-cool-grey !important;
      }
    }

    .dropdown-menu {
      left: auto !important;
      right: 0;
      border: none;
      -webkit-box-shadow: 0 13px 13px -3px rgba(64, 64, 64, 0.53);
      -moz-box-shadow: 0 13px 13px -3px rgba(64, 64, 64, 0.53);
      box-shadow: 0 13px 13px -3px rgba(64, 64, 64, 0.53);
    }

    .dropdown-item {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  .sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }

  .dropdown-toggle::after {
    display: none;
  }

  .loan-menu .dropdown-menu {
    top: 48px;
    border: none;
    -webkit-box-shadow: 0 13px 13px -3px rgba(64, 64, 64, 0.53);
    -moz-box-shadow: 0 13px 13px -3px rgba(64, 64, 64, 0.53);
    box-shadow: 0 13px 13px -3px rgba(64, 64, 64, 0.53);
  }

  .loan-menu.show {
    background-color: #ffffff;
    -webkit-box-shadow: 0 13px 13px -3px rgba(64, 64, 64, 0.53);
    -moz-box-shadow: 0 13px 13px -3px rgba(64, 64, 64, 0.53);
    box-shadow: 0 13px 13px -3px rgba(64, 64, 64, 0.53);

    .nav-link {
      color: $color-blue !important;
      font-weight: bold;
      position: relative;
    }
  }

  .dropdown-item {
    padding-left: 16px;
    padding-right: 16px;
  }

  p.dropdown-item {
    padding-left: 16px !important;
  }

  .cart-badge {
    width: 16px;
    height: 16px;
    color: #ffffff;
    font-size: 10px;
    border-radius: 50%;
    text-align: center;
    position: absolute;
    right: -8px;
    top: -11px;
    padding-top: 1px;
    font-weight: bold;
    background-color: $color-red;
  }

  .no-cart-item {
    font-size: 12px;
    font-weight: bold;
  }
}

.cart-item {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: solid 1px $color-light-grey;

  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .kit-image {
    width: 64px;
    height: 64px;
    margin-right: 15px;

    img {
      width: 100% !important;
      height: 100% !important;
      object-fit: cover;
      object-position: center;
    }
  }

  .kit-detail {
    .kit-name {
      font-weight: bold;
    }
    .kit-date {
      font-weight: 300;
    }
    .kit-delete {
      color: $color-blue;
      font-size: 12px;
      border: none;
      background: transparent;
      padding: 0 !important;

      span {
        padding-top: 5px;
      }
    }
  }
}

.header-container .user {
  .dropdown-menu {
    &::after {
        content: "";
        position: absolute;
        left: 89%;
        margin-left: -20px;
        border-width: 17px;
        border-style: solid;
        border-color: transparent transparent #fff transparent;
        top: -33px;
        margin-right: 9px;
    }

    width: 200px;
    padding: 15px;
    right: -6px;
  }
}

.header-container .cart {
  position: relative;

  .dropdown-menu {
    width: 306px;
    right: -32px !important;
    transform: translate3d(-32px, 48px, 0px) !important;

    .cart-container {
      max-height: 200px;
      overflow-y: auto;
    }
  }
}

.not-logged-in-loan-list-button {
  background-color: $color-blue;
  color: #FFFFFF;
  font-weight: bold;
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media(max-width: 1920px) {
  #loginModal .modal-dialog {
    margin-top: 160px;
  }
}

@media(max-width: 1280px) {

  .header-container .sticky .user-menu .nav-item {
    margin-left: 3px !important;
  }

  .header-container .sticky .navbar .nav-item {
    margin-right: 16px !important;
  }
}

@media(max-width: 1024px) {
  .header-container .navbar .nav-item .nav-link {
    padding: 24px 9px;
  }

  .header-container .dropdown-item {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 991px) {
  .header-container {
    padding-top: 0 !important;
  }
}
