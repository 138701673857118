.parent-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem;
}

.address-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 250px;
  padding: 1rem;
}

.text-capitalize{
  font-size: 17px;
  font-weight: bold;
  text-transform: capitalize !important;
}
