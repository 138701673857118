.key-header{
    width: Fixed (1,220px)px;
    height: Hug (57px)px;
    gap: 42px;
    border: 0px 0px 1px 0px;
    font-size: 14px;
    font-weight: 700;
    line-height: 16.88px;
    text-align: left;
    height: 17px;
    gap: 0px;
}
.value{
    font-size: 14px;
    font-weight: 300;
    line-height: 15px;
    text-align: left;
}
.full-row {
    border-bottom: 1px solid #f8f8f8;
}

.attach-to-label{
    margin-top: -6px !important;
}