@import "resources/styles/variables";

.file-uploader-container {
  cursor: pointer;

  .uploader {
    border: dashed 1px $color-cool-grey;
    padding: 24px;
    text-align: center;

    &:focus, &:active {
      outline: none !important;
      box-shadow: none !important;
    }
  }

  .allowed-formats-text {
    font-size: 12px;
  }

  p {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 300;
    margin-left: 8px !important;
  }

  .error-border {
    border: dashed 1px $error-red;
  }
}

.adjust-font-size-drag{
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
  color: #0B0B0B;
}
