@import 'resources/styles/variables';

.small-button-container {
  .small-button {
    display: flex;
    align-items: center;
    height: 24px;
    font-size: 12px;
    background-color: transparent;
    color: $color-blue;
    border: solid 1px $color-blue;
    font-weight: bold;
    cursor: pointer;

    &:disabled {
      opacity: 0.5;
    }
  }
  .dark {
    background-color: $color-blue;
    color: $white;
    border: solid 1px $color-blue;
  }
  .light {
    background-color: transparent;
    color: $color-blue;
    border: solid 1px $color-blue;
  }

  .ghost {
    background-color: transparent;
    color: $color-blue;
    border: none !important;
    font-weight: 800;
    font-size: 14px;
    line-height: 16.88px;
  }
  
  &.small-button-textonly{
    border: none;
  }

}
