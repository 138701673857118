.bg-filters{
    display: flex;
    box-shadow: 0px 4px 16px 0px #0000001A;
    padding: 15px 10px 15px 10px;
    margin-bottom: 20px;
}
  
.load-more-wrapper {
    display: flex;
    justify-content: center;
    align-items: center; 
    width: 100%; 
    bottom: 20px; 
    z-index: 10; 
  }