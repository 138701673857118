@import 'resources/styles/variables';

.opportunity-container {
  .opportunity-list-item {
    border-bottom: solid 1px $color-off-white;
    padding: 6px 0;

    &:first-child {
      border-top: solid 1px $color-off-white;
    }
  }

  .add-button {
    height: 40px;
    width: auto;
    white-space: nowrap
  }

  .text-bold {
    font-weight: bold;
  }
}
