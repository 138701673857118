textarea.form-control:focus {
  box-shadow: none;
  outline: none;
}

.components-textarea {
  height: 254px !important;
}

@media (max-width: 600px) {
  .components-textarea {
    height: 254px !important;
  }
}
