@import 'resources/styles/variables';

.uploader-wrapper {
  color: $color-cool-grey;
  height: 340px;
  width: 278px;

  .icon {
    fill: currentColor;
  }
}

.img-wrapper {
  border: 1px solid $color-light-grey;
  border-radius: 4px;
  margin: 0 32px 32px 0;
  padding: 10px;
  
  img {
    object-fit: contain;
  }
}

.img-button {
  color: $color-red;
  border: 1px solid $color-red;
  margin-top: 20px;
}
