@import 'resources/styles/variables';

.app-custom-table {
  .table {
    font-size: 12px;
    color: #000;
    border: 1px solid #F8F8F8 !important;

    thead {
      border-bottom: 1px solid $color-off-white;
      background-color: #f8f8f8;
    }

    tr {
      border-bottom: 1px solid $color-off-white;
    }

    thead th {
      vertical-align: middle !important;
    }
  }

  .button-blue {
    display: flex;
    align-items: center;
    background-color: transparent;
    color: $color-blue;
    border: none;
  }

  table th > div {
    height: 22px;
  }

  table td,
  table th {
    padding: 8px !important;
    font-size: 12px;
  }

  table td.expanded-td{
    padding-inline: 0 !important;
    padding-top: 0 !important;
  }
  

  .triangle-up {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 5px 5px;
    border-color: transparent transparent $color-light-grey transparent;
    margin-bottom: 2px;
  }

  .triangle-up-calendar-filter {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 5px 5px;
    border-color: transparent transparent $color-light-grey transparent;
    margin: 5px 0px 0px 0px;
  }

  .triangle-down {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 5px 5px;
    border-color: $color-light-grey transparent transparent transparent;
  }

  .triangle-down-calendar-filter {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 5px 5px;
    border-color: $color-light-grey transparent transparent transparent;
    margin: 5px 0px 0px 0px;
  }

  .active-triangle-up {
    border-color: transparent transparent $color-dark-gray transparent;
  }

  .active-triangle-down {
    border-color: $color-dark-gray transparent transparent transparent;
  }

  .custom-filter-dropdown {
    width: 80px;
    font-weight: bold;
    border: none;
    color: #0088d0;
    margin-left: auto;
    margin: 0px !important;
    padding: 0px !important;
    margin-top: 'auto';
    margin-bottom: 'auto';
    cursor: pointer;
  }

  .sortBy {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    padding: 0px;
    font-weight: bold;
  }
}

.expandable-chevron{
  transition: transform 0.3s ease-in-out;
}

.rotate-up {
  transform: rotate(-180deg);
}

.show-border-spacing{
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.15s ease;
  display: none;
  opacity: 0;
}

.show-border-spacing.open{
  max-height: 500px;
  opacity: 1;
  display: table-row;
}

.show-border-spacing.close{
  max-height: 0px;
  visibility: hidden;
  opacity: 0;
  display: none;
}

.expanded-row{
  background-color: #F1FBFF;
  box-shadow: 0px 4px 16px 0px #0000000D;
  padding: 1rem;
  border-spacing: 0 1em;
  // transition: min-height 0.3 ease-in-out;
}

.expanded-child{
  flex: 1;
  padding: 1rem;
}

.expanded-child-action-btns{
  padding: 1rem;
  flex-grow: .5;
}