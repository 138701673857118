.maintainStatus-container {
  .title {
    font-size: 36px;
    font-weight: bold;
  }

  .text {
    line-height: 40px;
    font-weight: bold;
    margin-right: 32px;
  }
}