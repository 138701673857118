.transaction-attachments-container {
  .toggle-container {
    margin-bottom: 24px;
  }

  .buttons {
    button {
      border: none;
      background-color: transparent;
    }
  }

  .save-button {
    max-width: 116px;
    margin-top: 30px;
  }
}

.height-increased-dropzone {
  height: 117px;
  border: 1px dashed #0088d0 !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.88px;
  color: #0088d0;
}

.kit-attachments-bordered {
  border: 1.1px dashed #0088d0 !important;
  font-weight: 600;
  line-height: 16.88px;
  color: #0088d0;
  font-size: 14px;
  border-radius: 2px;
  padding: 50px;
  gap: 10px;
  height: 44px;
  p {
    font-size: 14px !important;
    line-height: 1.5;
    font-weight: 600 !important;
    margin-left: 8px !important;
  }
}

.kit-pics-container .allowed-formats-text {
  font-size: 14px !important;
}
