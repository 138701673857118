@import 'resources/styles/variables';

/* The checkbox-container */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.label {
  font-size: 14px;
    font-weight: 300;
    line-height: 17px;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: transparent;
  border: solid 1px $color-blue;
}

.small-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: transparent;
  border: solid 1px $color-blue;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: #2196f3;
}

.checkbox-container input:checked ~ .small-checkmark {
  background-color: #2196f3;
}

/* When the checkbox is checked, add a gray background */
.checkbox-container input:disabled ~ .checkmark {
  background-color: #e0e1dd;
  border: #e0e1dd;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.small-checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-container input:checked ~ .small-checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  border-width: 0 1px 1px 0;
  left: 8px;
  top: 1px;
  width: 6px;
  height: 17px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkbox-container .small-checkmark:after {
  border-width: 0 1px 1px 0;
  left: 5px;
  top: 1px;
  width: 6px;
  height: 11px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* New UI styles */
.newui-checkmark, .newui-small-checkmark {
  height: 18px;
  width: 18px;
  border: solid 2px $color-blue;
  border-radius: 4px; /* Add border-radius for new UI checkboxes */
}

.newui-checkmark {
  background-color: transparent;
}

.newui-small-checkmark {
  background-color: transparent;
}

.checkbox-container input:checked ~ .newui-checkmark,
.checkbox-container input:checked ~ .newui-small-checkmark {
  background-color: rgba(33, 150, 243, 0.2);
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .newui-checkmark:after,
.checkbox-container input:checked ~ .newui-small-checkmark:after {
  display: block;
}
.checkbox-container input:disabled ~ .newui-checkmark {
  background-color: #e0e1dd;
  border: #e0e1dd;
}

/* Style the checkmark/indicator */
.newui-checkmark:after, .newui-small-checkmark:after {
  border-width: 0 1px 1px 0;
  left: 4px;
  top: -1px; /* Move the tick mark a bit above */
  width: 6px;
  height: 11px;
  border: solid $color-blue;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.white-checkbox{
  border: solid 1.5px $color-off-white !important;
  height: 16px !important;
  width: 16px !important;
  margin-left: 2px !important;
}
