.left-header {
  button {
    background: none;
    border: none;

    .left-arrow {
      transform: rotate(180deg);
    }
  }
}
