@import "resources/styles/variables";

.filter-form {
  background-color: $color-off-white;
  padding: 20px 16px 0 16px;
  margin-bottom: 24px;
  margin-right: -20px;
}

.search-box {
  margin-top: 14px;
}

.forms {
  min-height: 100px;
}

.location-address-edit{
  border: 1px solid rgba(0, 136, 208, 1);
  background-color: rgb(255, 255, 255);
  min-width: 80px;
  height: 33px;
  font-size: 14px;
  line-height: 16.94px;
  color: rgba(0, 136, 208, 1);
  padding: 7px;
}