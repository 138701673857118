@import "resources/styles/variables";

.app-radio-button {
  button {
    background-color: transparent;
    border: solid 1px $color-blue;
    color: $color-blue;
    height: 40px;

    &:hover {
      color: $color-blue;
    }
  }

  .active {
    background-color: $color-blue;
    color: #ffffff;

    &:hover {
      color: #ffffff;
    }
  }
}

.input-error {
  border-color: $error-red !important;
}
