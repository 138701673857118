.image-container {
  .toggle-container {
    margin-bottom: 24px;
  }

  .buttons {
    button{
      border: none;
      background-color: transparent;
    }
  }
}

.upload-attachment-popup-width{
  max-width: 641px !important;
}

.adjust-uploader{
  width: 601px;
  height: 116px;
  border: 1px dashed #1849D6 !important;
}

.display-fileName{
  border: 1px solid #E8E8E8;
  padding: 4px 6px;
  font-size: 12px;
  width: 602px;
  border-radius: 4px;
}

.adjust-delete-edit-icon{
  display: flex;
  align-items: center;
  gap: 10px;
}

.adjust-iqsSsc-add-room-btn{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 17px;
  height: 38px;
  margin-top: 7.2px;
  min-width: 132px;
}

.uploader-label-adjust{
  font-size: 14px;
  line-height: 16.94px;
  font-weight: 400;
  color: #000000;
}
