@import 'resources/styles/variables';

.horizontalBarChart-container {
  max-height: 640px;
  overflow: hidden;
  overflow-y: auto;

  .horizontalBarChart-wrapper {
    display: flex;
    max-height: 100%;
    position: relative;
  
    .item {
      height: 40px;
      margin-top: 10px;
      display: flex;
      align-items: center;
      font-weight: bold;
    }

    .header {
      position: sticky;
      top: 0;
      background: $white;
      font-size: 26px;
    }
    
    .date .header {
      visibility: hidden;
    }

    .date {
      color: $color-dark-gray;

      .item {
        font-size: 16px;
      }
    }
    
    .booking {
      flex: 1;
    }
    
    .booking .header {
      padding-right: 10px;
      text-align: right;
      color: $color-deep-blue;
    }
    
    .booking .content {
      padding: 0 10px;
      border-right: 1px solid $color-whisper;
    }
    
    .bookingItem {
      background: $color-deep-blue;
      color: $white;
      margin-left: auto;
      justify-content: flex-end;
      font-size: 18px;

      & > div {
        margin-right: 10px;
      }
    }
    
    .performed {
      flex: 1;
    }

    .performed .header {
      padding-left: 10px;
      color: $color-pacific-blue;
    }

    .planned {
      color: $color-saturated-gray;
    }

    .plannedItem {
      background: $color-saturated-gray;
      color: $white;
      font-size: 18px;

      & > div {
        margin-left: 5px;
      }
    }

    .performed .content {
      padding: 0 10px;
    }
    
    .performedItem {
      background: $color-pacific-blue;
      color: $white;
      font-size: 18px;

      & > div {
        margin-left: 5px;
      }
    }
  }  
}
