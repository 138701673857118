.progress-bar-container {
  margin-bottom: 36px;

  .title-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
  }

  .title {
    font-size: 24px;
  }

  .indicators {
    font-size: 24px;
    font-weight: bold;
  }

  .filler-wrapper {
    height: 5px;
    width: 100%;
    background: #c4c4c4;
  }

  .filler {
    height: 100%;
  }
}
