@import 'resources/styles/variables';

.notificationBar {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);

  .notificationBar-container {
    height: 100%;
    width: 425px;
    background-color: $white;
    padding: 10px;
  }

  .notificationBar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px;
    border-bottom: 1px solid $color-whisper;
  }

  .notificationBar-title {
    font-size: 22px;
  }

  .noNotifications-text, .loading-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }

  .notificationBar-menu {
    display: flex;
    align-items: center;
    color: $color-blue;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;

    .menu-item:hover {
      opacity: 0.7;
    }
  }

  .notifications-wrapper {
    height: 100%;
    overflow: auto;
    padding-bottom: 49px;
  }

  .notification {
    font-size: 14px;
    padding: 12px;
    border-bottom: 1px solid $color-whisper;
    cursor: pointer;
    &:hover {
      background: $color-off-white;

      .notification-date {
        display: none;
      }

      .buttons-wrapper {
        display: flex;
      }
    }
  }

  .notification-title {
    flex: 1;
    font-size: 12px;
    font-weight: bold;
  }

  .notification-date {
    width: 70px;
    text-align: end;
    font-size: 12px;
    color: $color-cool-grey;
  }

  .buttons-wrapper {
    display: none;
    justify-content: flex-end;
    width: 70px;
  }

  .eye {
    width: auto;
    padding: 0;
    color: #39BBFF;

    &:disabled {
      color: #39BBFF;
    }
  }

  .folder {
    width: auto;
    padding: 0;
    color: #646464;
  }

  .unread-notification {
    background: rgba(0, 136, 208, 0.05);
  }

  .dot-icon {
    height: 3px;
    width: 3px;
    border-radius: 50%;
    background-color: $color-blue;
  }
}
