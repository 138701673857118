.child-table-width {
  width: 75% !important;
}
@media(max-width: 1280px) {
  .child-table-width {
    width: 90% !important;
  }
}
@media(max-width: 600px) {
  .child-table-width {
    width: 100% !important;
  }
}