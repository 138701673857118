@import 'resources/styles/variables.scss';

.message-container {
  border: 1px solid $color-light-grey;
  margin-bottom: 15px;
  cursor: pointer;
  padding: 1em;

  .date-text, .phone-email-text, .message-text {
    font-family: $font-weight-regular;
    font-size: 16px;
  }
  .title-text {
    font-family: $font-weight-bold;
  }
  .phone-email-text {
    color: $color-blue;
  }
  .date-text {
    font-size: 12px;
    color: $color-cool-grey;
  }
  .message-text {
    max-height: 0;
    overflow: hidden;
    transition: all 0.4s ease-in-out;
    white-space: pre-line;
    word-wrap: break-word;
    &.opened {
      max-height: 1000px;
      margin-top: 15px;
    }
  }

  .arrow-icon {
    transform: rotate(-90deg);
    transition: all 0.2s ease-in-out;
    &.opened {
        transform: rotate(90deg);
    }
  }
}
