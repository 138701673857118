.quickstart-container {
  padding: 48px 0 0 0;
  color: #fff;
  height: 303px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .description-container{
  }

  .title {
    font-size: 33px;
    font-weight: bold;
    margin-bottom: 12px !important;
  }

  .description {
    font-weight: 300;
    text-align: justify;
  }

  .quickstart-item {
    display: flex;
    align-items: flex-start;
    padding: 1.7rem;
    margin: 0.5rem;
    box-sizing: border-box;
    height: 150px;
    width: 100%;
    border: 1px solid #E0E1DD;
    text-decoration: none;

    .item-name {
      font-size: 24px;
      color: black !important;
      font-weight: bolder;

      &hover {
        text-decoration: none !important;
      }
    }
  }

  .box-black-gradient {
    // background-image: linear-gradient(248deg, rgba(255, 255, 255, .4), rgba(0, 0, 0, 0.1));
    // background-color: #343a40 !important;
  }

  // .box-black-gradient:hover {
  //   background-image: linear-gradient(248deg, #0088d0, #0088d0);
  // }

  .blue-background {
    background-color: #0088d0;
  }
}

.sub-heading {
  font-size: 16px;
  color: black !important;
  font-weight: 300;
}
@media (max-width: 1024px) and (min-width: 992px) {
  .quickstart-container .quickstart-item .item-name {
    font-size: 14px;
  }
}

@media (max-width: 991px) {
  .quickstart-container {
    height: auto;
  }
}

.icon-size-set{
  height: 40px;
  width: 40px;
}



