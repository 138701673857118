:export {
  whiteColor: #fff;
  blackColor: #000;
  astronautColor: #273C75;
  toreaBayColor: #111E92;
  ceruleanColor: #0097E6;
  malibuColor: #7BD1FF;
  limeadeColor: #4AB300;
  blueVioletColor: #5758BB;
  javaColor: #12CBC4;
  atlantisColor: #A3CB38;
  dairyCreamColor: #F8EFBA;
  sanMarinoColor: #40739E;
  osloGrayColor: #9A9B9C;
  ceruleanColor: #0097E6;
  blueColor: #0088d0;
  deepBlueColor: #111E92;
  greenColor: #4ab300;
  darkGreenColor: #154d14;
  lightBlueSeesionColor: #97B3E7;
  navyBlue: #132387;
  redColor: #ff1a00;
  lightBlue: #91b3eb;
  darkBlue: #0b1f40;
  battleshipGrey: #747678;
  redColor: #ff1a00;
  appleGreen: #63BA3C;
  pureRed: #FF0000;
  brilliantBlue: #057df5;
  mediumGray: #808080;
  calendarResourceBg: #0F1F3E; }

.customTooltip {
  pointer-events: none;
  position: fixed;
  z-index: 1200;
  top: var(--top, auto);
  right: var(--right, auto);
  bottom: var(--bottom, auto);
  left: var(--left, auto);
  opacity: var(--opacity, 0);
  transition-property: opacity, transform;
  transition-timing-function: ease-out;
  transition-duration: 0.1s; }

.tooltipContent {
  background: #000;
  color: #fff;
  border-radius: 4px;
  font-size: 12px;
  line-height: 16px;
  padding: 6px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  max-width: 320px; }

.tooltipArrow {
  position: absolute;
  display: flex;
  justify-content: center; }

.tooltipArrow:before {
  content: '';
  width: 0;
  height: 0;
  overflow: hidden;
  border-color: transparent;
  border-width: 4px;
  border-style: solid; }

[data-direction='top'] .tooltipArrow {
  top: 100%; }
  [data-direction='top'] .tooltipArrow:before {
    border-top-color: #000; }

[data-direction='right'] .tooltipArrow {
  right: 100%; }
  [data-direction='right'] .tooltipArrow:before {
    border-right-color: #000; }

[data-direction='bottom'] .tooltipArrow {
  bottom: 100%; }
  [data-direction='bottom'] .tooltipArrow:before {
    border-bottom-color: #000; }

[data-direction='left'] .tooltipArrow {
  left: 100%; }
  [data-direction='left'] .tooltipArrow:before {
    border-left-color: #000; }

[data-direction='top'] .tooltipArrow,
[data-direction='bottom'] .tooltipArrow {
  left: 0;
  min-width: 20px;
  max-width: 100%;
  width: var(--arrow, 100%); }

[data-direction='top'][data-inverse] .tooltipArrow,
[data-direction='bottom'][data-inverse] .tooltipArrow {
  left: auto;
  right: 0; }

[data-direction='left'] .tooltipArrow,
[data-direction='right'] .tooltipArrow {
  flex-direction: column;
  top: 0;
  min-height: 20px;
  max-height: 100%;
  height: var(--arrow, 100%); }

[data-direction='left'][data-inverse] .tooltipArrow,
[data-direction='right'][data-inverse] .tooltipArrow {
  top: auto;
  bottom: 0; }
