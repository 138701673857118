@import "resources/styles/variables";

.add-more-delivery-time {

  .add-button {
    background-color: $color-blue;
    height: 40px;
    border: none;
  }

  @media (max-width: 425px) {
    .add-button {
      margin-top: -6px;
      margin-bottom: 18px;
    }
  }

  .remove-button {
    background-color: #ffffff;
    height: 40px;
    border: solid 1px $color-blue;
    margin-top: 18px;
  }
  @media (max-width: 425px) {
    .remove-button {
      margin-top: -6px;
      margin-bottom: 18px;
    }
  }
}
