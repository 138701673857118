.kit-attachments-container {
  .toggle-container {
    margin-bottom: 24px;
  }

  .buttons {
    button{
      border: none;
      background-color: transparent;
    }
  }
}
